import React, { useState } from 'react';
import { AppBar, Badge, Box, IconButton, Tab, Tabs, Toolbar } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import NotificationsIcon from '@mui/icons-material/Notifications';

import logo from './krita.svg';


import HomePage from './HomePage';
import AINews from './AINews';
import Configuration from './Configuration';
import CircularTab from './CircularTab';

const StockNotifications = () => {
  const [tabValue, setTabValue] = useState(0); // For tabs

  // Sample notification data


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      {/* AppBar with Notification Icon */}
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Box display="flex" alignItems="center" width="100%" flexDirection="row" justifyContent="space-between">
            {/* Left Logo */}
            <IconButton edge="start" color="inherit" aria-label="logo" sx={{ flexShrink: 0 }}>
              <img
                style={{ height: '30px', marginLeft: '20px', cursor: 'pointer' }}
                src={logo}
                alt="Krita"
                onClick={() => {
                  window.open('https://krita.ai/', '_blank');
                }}
              />
            </IconButton>

            {/* Center Tabs */}
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab label="News Alerts" sx={{fontWeight:'600'}} />
           
              <Tab label="Company Research" sx={{fontWeight:'600'}} />
              <Tab label="Circulars" sx={{fontWeight:'600'}} />
              <Tab label="Configuration" sx={{fontWeight:'600'}} />
           
            </Tabs>

            {/* Right Notifications */}
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                color="inherit"
                // onClick={() => setNotificationsOpen(true)} // Open notifications dialog
              >
                <Badge badgeContent={4} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              {/* User Section */}
              <IconButton
                color="inherit"
                sx={{
                  border: '1px solid #6EC1E4',
                  borderRadius: '5px',
                  size: 'small',
                }}
                size="small"
              >
                <span style={{ fontSize: '12px', marginRight: '5px', color: '#6EC1E4',fontWeight:'600' }}>LOGOUT</span>
                <TuneIcon fontSize="small" sx={{ color: '#6EC1E4' }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content based on Tabs */}
      <Box sx={{ p: 3 }}>
        {tabValue === 0 && <AINews  />}

        {tabValue === 1 && <HomePage />}

        {tabValue === 3 && <Configuration />}
        {tabValue === 2 && <CircularTab />}
      </Box>


    </Box>
  );
};

export default StockNotifications;
