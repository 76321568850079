// src/components/UserMessageCard.js
import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const UserMessageCard = ({ text }) => {
  return (
    <Card sx={{ backgroundColor: "#FFF", marginBottom: 2,borderRadius:'20px' ,marginLeft:'50px'}}>
      <CardContent>
        <Typography variant="body1" color="text.primary" sx={{textAlign:'left'}}>
          {text}
        </Typography>
        {/* <Typography variant="caption" color="text.secondary">
          You
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default UserMessageCard;
