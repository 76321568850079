/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  IconButton,
  TextField,
  Chip,
  Button,
  Paper,
  Card,
  Tooltip,
  Typography,
  alpha,
  CircularProgress,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  List,
  Divider,
  hexToRgb,
  Snackbar,
} from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // Filled version
import ThumbDownIcon from "@mui/icons-material/ThumbDown"; // Filled version
import SendIcon from "@mui/icons-material/Send";
import SortIcon from "@mui/icons-material/Sort";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FeedIcon from "@mui/icons-material/Feed";
import ArticleIcon from "@mui/icons-material/Article";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinIcon from "@mui/icons-material/PushPin"; // Example pin icon

import TwitterIcon from "@mui/icons-material/X";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import TimelineIcon from "@mui/icons-material/Timeline";
import UserMessageCard from "./components/UserMessageCard";
import BotMessageCard from "./components/BotMessageCard";
import botIcon from "./Chatbot.svg";

import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const sectors = [];
const FeedbackComponent = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [likeFilled, setLikeFilled] = useState(false);
  const [dislikeFilled, setDislikeFilled] = useState(false);

  const handleFeedbackOpen = (type) => {
    setFeedbackType(type);
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setLikeFilled(false);
    setDislikeFilled(false);
  };

  const handleFeedbackSubmit = (feedback) => {
    console.log(`Feedback (${feedbackType}): ${feedback}`);
    handleFeedbackClose();
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      sx={{ position: "relative", marginTop: "0px" }}
    >
      <Tooltip title="Like">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();

            setLikeFilled(true);
            handleFeedbackOpen("like");
          }}
        >
          {likeFilled ? (
            <ThumbUpIcon color="success" />
          ) : (
            <ThumbUpOffAltIcon color="success" />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Dislike">
        <IconButton
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation
            setDislikeFilled(true);
            handleFeedbackOpen("dislike");
          }}
        >
          {dislikeFilled ? (
            <ThumbDownIcon color="error" />
          ) : (
            <ThumbDownOffAltIcon color="error" />
          )}
        </IconButton>
      </Tooltip>
      <FeedbackBox
        open={feedbackOpen}
        onClose={handleFeedbackClose}
        onSubmit={handleFeedbackSubmit}
      />
    </Box>
  );
};

const FeedbackBox = ({ open, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = (e) => {
    e.stopPropagation();
    onSubmit(feedback);
    setFeedback("");
  };

  return (
    open && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "-70px", // Adjust this value as needed
          right: "70px",
          width: "300px",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          zIndex: 1200, // Ensures it appears above other content
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Enter your feedback..."
          value={feedback}
          onChange={(e) => {
            e.stopPropagation();
            setFeedback(e.target.value);
          }}
          sx={{ marginBottom: "8px" }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleSubmit}
            size="small"
            endIcon={<SendIcon />}
            sx={{
              marginRight: "8px",
              color: "#fff",
              background: "#6EC1E4",
              "&:hover": {
                backgroundColor: "#6EC1E4",
                color: "#fff",
              },
            }}
          >
            Send
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={(e) => {
              onClose();
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    )
  );
};

const DataCard = ({ data }) => {
  const { sequence_no, date, source, title, department, topics, summary } =
    data;

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSave = (e) => {
    e.stopPropagation();
    // Save action logic (e.g., API call, localStorage update)
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Card
        sx={{
          padding: "16px",
          // paddingTop: "32px",

          marginBottom: "16px",
          borderRadius: "12px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
               <Typography variant="h6" sx={{ color: "#2E3B4E", flex: 1, marginRight: "8px", fontSize: "18px" ,fontWeight:'550'}}>

            # {title}
          </Typography>
        </Box>

        {/* Details Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // marginBottom: "16px",
          }}
        >
          <Typography variant="body2" sx={{ color: "#808080" }}>
            Source: {source} - {date}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: "#555" }}>
           
          </Typography> */}
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {topics.map((topic, index) => (
              <Tooltip key={index} title={topic} arrow>
                <Chip
                  label={topic}
                  sx={{
                    marginRight: "8px",
                    marginBottom: "8px",
                    backgroundColor: "#007680",
                    color: "#FFF",
                    fontWeight: "550",
                  }}
                />
              </Tooltip>
            ))}
          </Box>
        </Box>

        {/* Topics Section */}

        <Chip
          label={department}
          sx={{
            marginRight: "8px",
            marginBottom: "8px",
            backgroundColor: "#f3e5f5",
            color: "#79155B",
            border: "1px solid #79155B",
            fontWeight: "550",
          }}
        />
        {/* Summary Section */}
        <Typography variant="body1" sx={{ color: "#5F6368" }}>
          {summary}
        </Typography>

        {/* Save Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            // marginTop: "16px",
          }}
        >
          <FeedbackComponent />
          {/* <SaveAltIcon
  
  onClick={handleSave}
  sx={{
    marginTop:'8px',
    fontSize:'25px',
    marginLeft:'30px',
    textTransform: "none",
    color: "#6EC1E4",
    fontWeight:'550',
    
  }}
  
></SaveAltIcon> */}
        </Box>
      </Card>

      {/* Snackbar */}
    </>
  );
};

const CircularTab = () => {
  const content = [
    {
      "date": "25/Oct/2024",
      "circular_no": "NSE/INSP/64718",
      "highlights": [
        "Revised guidelines on the treatment of inactive trading accounts.",
        "Expanded definition of activities considered for determining inactive accounts.",
        "Mandatory In-Person Verification (IPV) or Video In-Person Verification (VIPV) for reactivation.",
        "Confirmation and update of client details during reactivation.",
        "Clarification on the calculation of the 24-month inactivity period after reactivation.",
        "Exemption from reporting inactive clients with NIL balances in holding statements.",
        "Emphasis on ongoing client due diligence, including Re-KYC."
      ],
      "title": "Revised Guidelines for Treatment of Inactive Trading Accounts",
      "summary": "The NSE has issued revised guidelines for the treatment of inactive trading accounts, expanding the criteria for inactivity and outlining procedures for reactivation and ongoing due diligence.",
      "detailed_notes": "The NSE has revised its guidelines for handling inactive trading accounts. An account is now considered inactive if there has been no trading activity, OFS/buy-back/Open Offer participation, IPO/SGB/Mutual Fund transactions, or KYC updates for 24 months.  For reactivation, In-Person Verification (IPV) or Video In-Person Verification (VIPV) is mandatory.  Members must confirm and update client details like address, mobile number, email ID, bank/DP account, and income during reactivation. The 24-month period for inactivity is calculated from the last reactivation date.  Members are exempt from reporting inactive accounts with NIL balances in holding statements.  Ongoing client due diligence, including Re-KYC, is emphasized.",
      "topics": [
        "Inactive Accounts",
        "KYC/AML",
        "Client Due Diligence"
      ],
      "source": "NSE",
      "example": "If an investor hasn't traded, participated in OFS/buy-back/Open Offers, invested in IPOs/SGBs/Mutual Funds, or updated their KYC details in the last 24 months, their account will be flagged as inactive.  To reactivate the account, they will need to complete IPV/VIPV and confirm their KYC information with their broker.",
      "customer_communication": "Dear Investor, The NSE has updated its rules on inactive trading accounts. Your account may be flagged as inactive if there's been no activity for 24 months. This includes trading, IPO/Mutual Fund investments, and KYC updates.  If your account becomes inactive, you'll need to complete an in-person verification to reactivate it and confirm your details with your broker. Please contact your broker for more details.",
      "circular_link": "https://storage.googleapis.com/ishield-screener-static-files/docs/INSP64718.pdf",
      "department": "Inspection & Compliance",
      "original_circular_link": "https://storage.googleapis.com/ishield-screener-static-files/docs/INSP43488.pdf",
      "original_circular_date": "10/Feb/2020",
      "original_circular_highlights": [
        "Trading members were required to frame a policy for handling inactive accounts.",
        "Accounts were flagged as inactive if no trades were executed in the last 12 months.",
        "Due diligence and IPV were required for reactivating inactive accounts.",
        "Members were required to upload client status in the UCC database before trade execution.",
        "Members were advised to settle client accounts monthly or quarterly.",
        "Exemption from reporting inactive clients with NIL balances was granted."
      ],
      "what_has_changed": [
        {
          "topic": "Definition of Inactivity",
          "changes": [
            "The period of inactivity has increased from 12 months to 24 months.",
            "The definition of 'activity' has broadened to include not only trading but also participation in corporate actions, IPO/SGB/Mutual fund applications, and KYC updates."
          ]
        },
        {
          "topic": "Reactivation Process",
          "changes": [
            "IPV/VIPV is now mandatory for all reactivations.",
            "Explicit requirement to confirm and update client details upon reactivation."
          ]
        },
        {
          "topic": "Reporting Requirements",
          "changes": [
            "Clarification that inactive clients with NIL balances are not required to be reported in holding statements, but clients with funds/securities balances must be reported regardless of status."
          ]
        }
      ]
    },
    {
      "date": "22/Oct/2024",
      "circular_no": "SEBI/HO/AFD/AFD-POD-3/P/CIR/2024/145",
      "highlights": [
        "The Common Application Form (CAF) for FPIs based in IFSCs has been modified.",
        "A new option has been inserted in Section B-II regarding NRI/OCI/RI entitlement.",
        " This new option is for FPIs based in IFSCs with NRI/OCI/RI investment exceeding 50%."
      ],
      "title": "Modification to the Common Application Form (CAF) for FPIs in IFSCs",
      "summary": "SEBI has modified the CAF for FPIs based in International Financial Services Centres (IFSCs). The modification provides an additional option in the CAF to cater to FPIs with greater than 50% investment from NRIs, OCIs, and RIs.",
      "detailed_notes": "SEBI has modified the 'Annexure to Common Application Form' (Annexure B of the FPI Master Circular).  A new option has been introduced under ‘Section B-II: NRI/OCI/RI Entitlement in FPI' in Para 5 of Part B (titled 'Additional information'). This applies only to applicants based in IFSCs in India.  This option covers scenarios where aggregate NRI/OCI/RI contributions exceed 50% of the FPI corpus. It clarifies that FPIs using this option must remain compliant with SEBI (Foreign Portfolio Investors) Regulations, 2019, and the FPI Master Circular.  The modification is in line with a previous circular (June 27, 2024) that granted flexibility for up to 100% aggregate NRI/OCI/RI contribution to FPIs in IFSCs.",
      "topics": [
        "FPI Regulations",
        "IFSC",
        "CAF"
      ],
      "source": "SEBI",
      "example": "An FPI based in an IFSC previously had a restriction limiting the combined contributions from NRIs, OCIs, and RIs to less than 50% of the FPI's corpus. Under the new circular, an FPI can now structure itself to accept more than 50% of its corpus from such investors, given the FPI is based in an IFSC and declares this arrangement in the modified CAF.",
      "customer_communication": "Dear Investor/Trader,\n\nSEBI has eased the investment norms for FPIs based in IFSCs.  If you are an FPI operating within an IFSC, you can now accept aggregate investments from NRIs, OCIs, and RIs exceeding 50% of your corpus.  To avail this option, you need to declare this structure while applying/ renewing your FPI registration through the modified Common Application Form. This change provides greater flexibility to FPIs seeking funding from this investor segment.\n\nSincerely,\n[Your Brokerage Firm]",
      "circular_link": "https://www.sebi.gov.in/sebi_data/attachdocs/oct-2024/1729596322788.pdf",
      "department": "Alternative Investment Fund and Foreign Portfolio Investors Department",
      "original_circular_link": "https://www.sebi.gov.in/legal/master-circulars/may-2024/master-circular-for-foreign-portfolio-investors-designated-depository-participants-and-eligible-foreign-investors-_83689.html",
      "original_circular_date": "30/May/2024",
      "original_circular_highlights": [
        "The Master Circular for FPIs, DDPs, and EFIs was issued.",
        "This Master Circular consolidated and superseded the previous Master Circular (dated Dec 19, 2022) and several subsequent circulars.",
        " The Master Circular provided guidance on various aspects of FPI registration, operation, and compliance."
      ],
      "what_has_changed": [
        {
          "topic": "Modification of Common Application Form (CAF) for IFSC-Based FPIs",
          "changes": [
            "The original circular (May 30, 2024) was a master circular consolidating FPI rules, while the new circular (October 22, 2024) specifically modifies the Common Application Form.",
            "The new circular allows for greater than 50% NRI/OCI/RI investment in FPIs located in IFSCs, reflecting a liberalization of the FPI regime for IFSCs introduced in a prior circular (June 27, 2024).",
            "The new circular necessitates a declaration in the CAF for IFSC-based FPIs exceeding 50% NRI/OCI/RI investment. This was not required in the earlier master circular."
          ]
        }
      ]
    },
    {
      "date": "10/Oct/2024",
      "circular_no": "SEBI/HO/MRD/MRD-PoD-2/P/CIR/2024/137",
      "highlights": [
        "Payout of securities will be credited directly to the client's demat account on the same trading day.",
        "The timing of the payout of securities shall be revised from 1:30 PM to 3:30 PM.",
        "This change is applicable to the equity cash segment (including netted cash and F&O Physical Settlement)."
      ],
      "title": "Change in Timing for Securities Payout in T+1 Rolling Settlement",
      "summary": "SEBI has revised the timing for securities payout in the T+1 rolling settlement. Securities will now be credited directly to client demat accounts on the same trading day, between 3:30 PM, instead of the next working day.",
      "detailed_notes": "SEBI has mandated a change in the timing of securities payout in the T+1 rolling settlement.  As per the previous circular (SEBI/HO/MIRSD/MIRSD-PoD1/P/CIR/2024/75 dated June 5, 2024), the payout of securities is to be credited directly to the client account by the Clearing Corporations (CCs). Consequently, the timing of the payout of securities will be revised from 1:30 PM to 3:30 PM. This means securities will be credited to the clients' demat account on the same trading day, instead of one working day after the receipt of payout from the exchange. This applies to the equity cash segment, including netted cash and F&O Physical Settlement. The Stock Exchanges, Clearing Corporations, and Depositories are required to make necessary amendments to their bye-laws, rules, and regulations to implement this decision.",
      "topics": [
        "T+1 Settlement",
        "Securities Payout",
        "Demat Account"
      ],
      "source": "SEBI",
      "example": "Previously, if an investor sold shares on Monday, they would receive the credit in their demat account on Tuesday. With this change, the credit will now be given on the same day, Monday, by 3:30 PM.",
      "customer_communication": "Dear Investor, SEBI has announced a change in the settlement process for trades. Starting October 14, 2024, when you sell shares, the proceeds will be credited directly to your demat account on the same trading day by 3:30 PM.  This will give you quicker access to your funds.",
      "circular_link": "https://www.sebi.gov.in/sebi_data/attachdocs/oct-2024/1728572020490.pdf",
      "department": "Market Regulation Department",
      "original_circular_link": "https://www.sebi.gov.in/legal/circulars/jun-2024/enhancement-of-operational-efficiency-and-risk-reduction-pay-out-of-securities-directly-to-client-demat-account_83930.html",
      "original_circular_date": "05/June/2024",
      "original_circular_highlights": [
        "Mandated the payout of securities to be credited directly to the client's demat account by Clearing Corporations.",
        "Specified processes for handling client securities with regard to pay-in and pay-out.",
        "Addressed the handling of client funds through upstreaming and downstreaming mechanisms.",
        "Amended the master circular for stockbrokers regarding funded stocks under the margin trading facility."
      ],
      "what_has_changed": [
        {
          "topic": "Securities Payout Timing and Crediting",
          "changes": [
            "The timing of the payout of securities has been changed from 1:30 PM to 3:30 PM.",
            "Securities will now be credited to the client's demat account on the same trading day, instead of the next working day."
          ]
        }
      ]
    },
    {
      "date": "10/Oct/2024",
      "circular_no": "SEBI/HO/MIRSD/MIRSD-PoD1/P/CIR/2024/136",
      "highlights": [
        "Extends the implementation timeline of SEBI Circular SEBI/HO/MIRSD/MIRSD-PoD1/P/CIR/2024/75 from October 14, 2024, to November 11, 2024.",
        "The delay is due to extensive consultations and the need to ensure smooth implementation of direct payout of securities to client demat accounts.",
        "Stock Exchanges, Depositories, and Clearing Corporations are directed to inform members, disseminate the circular on their websites, and make necessary system and by-law amendments."
      ],
      "title": "Extension of Timeline for Implementation of Direct Payout of Securities to Client Demat Accounts",
      "summary": "This circular extends the deadline for implementing direct payout of securities to client demat accounts from October 14, 2024, to November 11, 2024, to ensure a smooth transition and avoid market disruptions.  Stock Exchanges, Depositories, and Clearing Corporations are instructed to implement accordingly.",
      "detailed_notes": "This circular extends the timeline for the implementation of SEBI Circular SEBI/HO/MIRSD/MIRSD-PoD1/P/CIR/2024/75, which mandates the direct payout of securities to clients' demat accounts.  The original implementation date was October 14, 2024, but due to extensive consultations and the need for smooth implementation, the new effective date is November 11, 2024. Stock exchanges, depositories, and clearing corporations are directed to bring this to the notice of their members and make necessary system and by-law changes.",
      "topics": [
        "Demat Account Payout",
        "Securities Market Regulation",
        "Regulatory Compliance"
      ],
      "source": "SEBI",
      "example": "Previously, brokers pooled securities before transferring them to investor demat accounts. This circular mandates that securities are credited directly to the investor's demat account, improving efficiency and reducing risk of loss.  This change may lead to quicker settlement times and reduced operational costs for brokers, potentially impacting trading fees.",
      "customer_communication": "Dear Investor,\n\nSEBI has extended the date for implementing a new system for transferring securities directly to your demat account.  This will be effective from November 11, 2024, instead of October 14, 2024.  This change will improve efficiency and reduce risks in the securities market. You don't need to take any action at this time. Your broker will handle the change in their processes.\n\nSincerely,\n[Your Brokerage Firm]",
      "circular_link": "https://www.sebi.gov.in/sebi_data/attachdocs/oct-2024/1728560678871.pdf",
      "department": "Market Intermediaries Regulation and Supervision Department",
      "original_circular_link": "https://www.sebi.gov.in/legal/circulars/jun-2024/enhancement-of-operational-efficiency-and-risk-reduction-pay-out-of-securities-directly-to-client-demat-account_83930.html",
      "original_circular_date": "05/Jun/2024",
      "original_circular_highlights": [
        "Mandated pay-out of securities directly to the client's demat account to enhance operational efficiency and reduce risk.",
        "Specified processes for handling clients' securities regarding pay-in and pay-out.",
        "Initially planned to come into effect from October 14, 2024."
      ],
      "what_has_changed": [
        {
          "topic": "Direct Payout to Client Demat Accounts",
          "changes": [
            "The implementation date has been postponed from October 14, 2024, to November 11, 2024."
          ]
        }
      ]
    },
    {
      "date": "16/Sep/2024",
      "source": "NSDL",
      "circular_no": "NSDL/POLICY/2024/0130",
      "title": "Revised Pay-in & Pay-out Times for NSE and BSE Settlements",
      "highlights": [
        "Changes to pay-in and pay-out timings for NSE and BSE settlements due to a settlement holiday on September 18, 2024.",
        "NSE settlements: Pay-in deadline advanced by 10 minutes for morning settlements; some settlements delayed to the afternoon.",
        "BSE settlements: Similar adjustments to deadlines, some earlier, some later.",
        "Revised pay-in and pay-out timings for Securities Lending and Borrowing (SLB) settlements on both exchanges.",
        "For certain settlement numbers, auction settlements will not be held; shortages handled in subsequent auctions."
      ],
      "summary": "This circular announces changes to the pay-in and pay-out times for NSE and BSE settlements due to a settlement holiday.  The adjustments impact deadlines for submitting and receiving securities/funds, with revised timings for SLB settlements and auction settlements.",
      "topics": [
        "Settlement Timing",
        "NSE/BSE"
      ],
      "customer_communication": "Dear Customer,\nWe would like to inform you of some important changes regarding the settlement process for trades executed on the National Stock Exchange (NSE) and Bombay Stock Exchange (BSE). Due to a scheduled settlement holiday on September 18, 2024, the deadlines for pay-in (securities or funds deposit) and pay-out (securities or funds received) timings have been adjusted for settlements occurring on September 19, 2024.",
      "circular_link": "https://storage.googleapis.com/ishield-screener-static-files/docs/2024-0130-Policy-Change_in_Pay-in__Pay-out_time_for_NSE_and_BSE_settlements.pdf",
      "department": "Policy"
    },
    {
      "date": "18/Sep/2024",
      "circular_no": "NSDL/POLICY/2024/0132",
      "highlights": [
        "Annual System Audit reports are now split into two: System Audit and Cyber Security Audit.",
        "Separate reports are required starting FY 2024-25.",
        "Corrective Action Taken Reports (ATR) must be submitted for non-compliances.",
        "Auditors must assign risk ratings (High, Medium, or Low) to non-compliances.",
        "Participants should time audits to enable sharing of Cyber Security Audit report with System Audit auditor."
      ],
      "title": "Submission of separate Annual System Audit and Cyber Security Audit reports starting FY 2024-25",
      "summary": "Starting FY 2024-25, participants must submit separate System and Cyber Security audit reports annually within three months of the financial year-end.  A Corrective Action Taken Report (ATR) is due within three months of the report submission date for any non-compliance, with assigned risk ratings.",
      "detailed_notes": "This circular updates the requirements for annual system audits based on guidance from SEBI. From FY 2024-25 onwards, participants must submit two separate reports: one for System Audit and one for Cyber Security Audit, within three months of the financial year's end (by June 30th). For every non-compliance reported, participants must also submit a Corrective Action Taken Report (ATR) within three months of the original report's due date (by September 30th). Auditors are required to assign risk ratings (High, Medium, or Low) to each reported non-compliance. Participants are advised to schedule their audits such that the Cyber Security Audit report can be shared with the auditor conducting the System Audit.",
      "topics": [
        "System Audit",
        "Cyber Security",
        "Risk Management"
      ],
      "source": "National Securities Depository Limited (NSDL)",
      "example": "A stockbroker offering online trading services would be required to get two separate audits done annually, one focused on their overall IT systems and another on their cyber security framework. For example, if the System Audit finds inadequate backup and recovery planning, the broker must submit an ATR detailing corrective actions.  Similarly, if the Cyber Security Audit finds vulnerability to phishing attacks, this would trigger an ATR with a risk rating. This process ensures continuous improvement in both system integrity and cyber resilience.",
      "customer_communication": "Dear Investor/Trader,\n\nStarting next financial year (FY 2024-25), stockbrokers and depository participants like us will be required to get two separate annual audits performed: a System Audit and a Cyber Security Audit. This is to enhance the security and resilience of our IT infrastructure and protect your investments.\n\nThe System Audit will cover the general integrity of our systems and processes, while the Cyber Security Audit will focus specifically on our cyber defenses.  Both audits will be conducted by qualified independent auditors and reports will be submitted to regulatory bodies.\n\nThis change ensures stronger safeguards for your investments and reinforces our commitment to providing secure and reliable services.",
      "circular_link": "https://storage.googleapis.com/ishield-screener-static-files/docs/2024-0132-Policy-Submission_of_Annual_System_Audit_Report.pdf",
      "department": "Policy",
      "original_circular_link": "https://www.sebi.gov.in/legal/circulars/dec-2018/cyber-security-and-cyber-resilience-framework-for-stock-brokers-depository-participants_41215.html",
      "original_circular_date": "03/Dec/2018",
      "original_circular_highlights": [
        "Introduced a mandatory Cyber Security & Cyber Resilience framework for stock brokers and depository participants.",
        "Framework was designed in consultation with exchanges, depositories, and market participant associations.",
        "Framework was applicable from April 1, 2019.",
        "Stock exchanges and depositories were required to make necessary amendments to their byelaws, rules, and regulations.",
        "A single, consolidated system audit was mandated to check compliance with the framework."
      ],
      "what_has_changed": [
        {
          "topic": "Audit Report Changes",
          "changes": [
            "Previously, a single consolidated report covered both system and cyber security aspects.  Now, two separate reports are required: one for System Audit and another specifically for Cyber Security Audit.",
            "The due date of the report submission has changed from \"within three months of financial year\" to \"by June 30th\", with the ATR submission deadline set as \"by September 30th\"."
          ]
        },
        {
          "topic": "Audit Finding Management",
          "changes": [
            "A timeline for Corrective Action Taken Reports (ATR) submission, along with assigned risk ratings (High/Medium/Low), has been introduced for audit findings."
          ]
        }
      ]
    },
    {
      "date": "17/Sep/2024",
      "source": "CDSL",
      "circular_no": "CDSL/OPS/DP/POLCY/2024/544",
      "title": "Separate Billing for DP Charges, CISA Overdue Charges, and Monetary Penalties",
      "highlights": [
        "Starting September 2024, DP charges, CISA overdue charges, and monetary penalties will be billed separately.",
        "Three separate invoices will be issued: Monthly DP charges, CISA overdue charges (if applicable), and Monetary penalty (if applicable).",
        "All invoices will be uploaded in the billing folder during the 1st week of the following month."
      ],
      "summary": "CDSL announces changes to its billing process, effective September 2024.  DP charges, CISA overdue charges, and monetary penalties will now be billed separately with separate intimation emails for each invoice.",
      "topics": [
        "Billing Changes",
        "CDSL Invoices"
      ],
      "customer_communication": "Dear Team,\nPlease note the changes announced by CDSL regarding the billing process for Depository Participants (DPs), effective from September 2024.",
      "circular_link": "https://www.cdslindia.com/common/DownLoadFile.aspx?eventid=DP2024-544&action=communique",
      "department": "POLICY"
    },
    {
      "date": "16/Sep/2024",
      "source": "NSDL",
      "circular_no": "CDSL/OPS/DP/POLCY/2024/538",
      "title": "Modifications in Guidelines for Business Continuity",
      "highlights": [
        "All stock exchanges, clearing corporations, and depositories must maintain a Near Site (NS) alongside their Disaster Recovery Site (DRS).",
        "Staff at the Disaster Recovery Site must have the same expertise as the main site.",
        "Recovery Point Objective (RPO) must be near zero for stock exchanges and zero for clearing corporations and depositories.",
        "System architecture at PDC, DRS, and NS must ensure high availability, fault tolerance, and no single point of failure. Synchronous replication between PDC and NS; asynchronous replication allowed between PDC, NS, and DRS.",
        "MIIs must create a common definition of \"near zero data loss\" and submit it to SEBI."
      ],
      "summary": "SEBI introduces changes to Business Continuity Plan (BCP) and Disaster Recovery (DR) guidelines for Market Infrastructure Institutions (MIIs).  Key updates include mandatory Near Sites (NS), expert staff at DRS, near-zero data loss requirements, and system architecture modifications.",
      "topics": [
        "Business Continuity",
        "Disaster Recovery"
      ],
      "customer_communication": "SEBI has introduced key changes to the Business Continuity Plan (BCP) and Disaster Recovery (DR) guidelines. These changes impact how we manage data recovery and system operations during disasters.",
      "circular_link": "https://www.cdslindia.com/common/DownLoadFile.aspx?eventid=DP2024-538&action=communique",
      "department": "POLICY"
    },
    {
      "date": "13/Sep/2024",
      "source": "NSDL",
      "circular_no": "NSDL/POLICY/2024/0128",
      "title": "Automatic Cancellation of Unconfirmed Pledges after 15 Days",
      "highlights": [
        "If a pledge request isn't confirmed by the pledgee within 15 days, it will be automatically canceled.",
        "Securities will be returned to the pledgor's free balance on the 16th day.",
        "This does not apply to Margin Pledge, Repledge, or MTF pledges.",
        "The system will update the status of canceled pledges in the Pledge Master Report and other transaction reports; error code PRNV will be shown."
      ],
      "summary": "NSDL implements automatic cancellation of unconfirmed pledge requests after 15 days.  Securities will be returned to the pledgor, with system updates reflecting the cancellation and providing error code PRNV.",
      "topics": [
        "Pledge Cancellation",
        "NSDL Policy"
      ],
      "customer_communication": "I hope this message finds you well.\nPlease be informed that starting September 16, 2024, NSDL will implement new rules regarding the automatic cancellation of unconfirmed pledge requests.",
      "circular_link": "https://storage.googleapis.com/ishield-screener-static-files/docs/2024-0128-Policy-Cancellation_of_Unconfirmed_Pledges_after_fifteen_days_of_the_receipt_of_the_pledge_initiation_request.pdf",
      "department": "POLICY"
    },
    {
      "date": "20/Aug/2024",
      "circular_no": "SEBI/HO/ITD-1/ITD_CSC_EXT/P/CIR/2024/113",
      "highlights": [
        "Introduces the Cybersecurity and Cyber Resilience Framework (CSCRF) for SEBI Regulated Entities (REs).",
        "CSCRF supersedes all existing SEBI cybersecurity circulars, guidelines, and advisories.",
        "Mandates all REs to establish a Security Operations Center (SOC).",
        "Classifies REs into five categories based on operational scale and thresholds.",
        "Sets different compliance timelines based on RE category."
      ],
      "title": "Cybersecurity and Cyber Resilience Framework (CSCRF) for SEBI Regulated Entities",
      "summary": "SEBI introduces CSCRF to strengthen cybersecurity measures and ensure adequate cyber resilience against evolving cyber threats.\nThe framework provides a structured methodology and standards for REs to implement various cybersecurity solutions.",
      "detailed_notes": "The Securities and Exchange Board of India (SEBI) has issued a new circular introducing the Cybersecurity and Cyber Resilience Framework (CSCRF) for SEBI Regulated Entities (REs).  This framework aims to strengthen cybersecurity measures in the Indian securities market and ensure sufficient cyber resilience against evolving threats. The CSCRF consolidates and replaces existing SEBI cybersecurity circulars and guidelines, providing a standardized approach for implementation.  Key objectives include addressing new cyber threats, aligning with industry standards, enabling efficient audits, and ensuring compliance. The framework covers various areas such as IT services, Software as a Service (SaaS) solutions, data classification, and audit requirements for software used by REs.  It also mandates the establishment of a Security Operations Center (SOC) and categorizes REs into five groups with varying compliance timelines based on their scale of operations. This framework will be updated to address future security needs, including those arising from quantum computing.",
      "topics": [
        "Cybersecurity",
        "Cyber Resilience",
        "SEBI"
      ],
      "source": "SEBI",
      "example": "A mid-size stock brokerage firm will now be required to not only comply with the CSCRF framework by April 1, 2025, but also establish an SOC or join a Market SOC.  They'll need to conduct periodic risk assessments, vulnerability assessments and penetration testing (VAPT), and cyber audits.  They will also need to report their compliance according to the prescribed timelines and formats.",
      "customer_communication": "Dear Investor/Trader,\n\nSEBI has introduced a new framework, CSCRF, to enhance cybersecurity in the securities market. This means stronger protection against cyber threats for your investments.  Your brokerage firm/ other investment entities will be implementing new security measures, including setting up a security operations center and regular audits.  These measures aim to create a more robust and secure environment for trading and investing. You may see some changes in how your firm operates as they adapt to these new regulations. For more information, please visit the SEBI website or contact your brokerage firm.",
      "circular_link": "https://storage.googleapis.com/ishield-screener-static-files/docs/1724326790365.pdf",
      "department": "ITD",
      "original_circular_link": "https://www.sebi.gov.in/web/?file=/sebi_data/attachdocs/1436179654531.pdf",
      "original_circular_date": "06/Jul/2015",
      "original_circular_highlights": [
        "Established a cybersecurity and cyber resilience framework specifically for Market Infrastructure Institutions (MIIs).",
        "Outlined basic cybersecurity requirements for MIIs.",
        "Focused on incident response and recovery planning.",
        "Aimed to protect critical market infrastructure.",
        "Did not provide a graded approach or specific compliance formats."
      ],
      "what_has_changed": [
        {
          "topic": "Scope",
          "changes": [
            "Expanded scope to cover all SEBI Regulated Entities (REs), not just MIIs.",
            "Introduced a graded approach to cater to different RE categories based on operational scale.",
            "Introduced comprehensive, structured standards and guidelines for implementation."
          ]
        },
        {
          "topic": "Requirements",
          "changes": [
            "Mandated the establishment of a Security Operations Center (SOC) for all REs.",
            "Introduced Cyber Capability Index (CCI) for MIIs and Qualified REs for periodic self-assessment.",
            "Included specific guidelines on data security, data localization, application security, and cloud service adoption.",
            "Added provisions for dealing with future threats, like those arising from quantum computing."
          ]
        },
        {
          "topic": "Compliance and Reporting",
          "changes": [
            "Standardized reporting formats for compliance submission.",
            "Introduced a defined timeline for compliance and reporting based on RE category.",
            "Added provisions for cyber audits by CERT-In empanelled IS auditing organizations.",
            "Included an auditor's checklist and guidelines for uniformity in audits."
          ]
        }
      ]
    },
    {
      "date": "12/Jul/2023",
      "circular_no": "SEBI/HO/CFD/CFD-SEC-2/P/CIR/2023/122",
      "highlights": [
        "Introduces BRSR Core for assurance by listed entities and for the value chain.",
        "Amends the SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015.",
        "Specifies the data and approach for reporting and assurance for BRSR Core.",
        "Sets a phased implementation plan for BRSR Core assurance based on market capitalization."
      ],
      "title": "BRSR Core Framework for Assurance and ESG Disclosures for Value Chain",
      "summary": "This circular introduces the BRSR Core, a subset of BRSR focusing on key ESG performance indicators, for assurance by listed entities and their value chains.  It mandates reasonable assurance for the BRSR Core for top listed entities on a phased basis.",
      "detailed_notes": "This circular introduces the BRSR Core for assurance by listed entities and their value chain. The BRSR Core is a subset of the BRSR, consisting of key performance indicators under 9 ESG attributes. New KPIs have been identified for assurance, such as job creation in small towns, business openness, gross wages paid to women etc.  Intensity ratios based on revenue adjusted for Purchasing Power Parity (PPP) have been included.  The applicability of BRSR Core disclosures and assurance is based on market capitalization and financial year. ESG disclosures for the value chain are also mandated, with specified reporting requirements. The circular also makes amendments to SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015.",
      "topics": [
        "BRSR Core",
        "ESG Disclosure",
        "Assurance"
      ],
      "source": "SEBI",
      "example": "A mid-sized company listed on the NSE, previously only required to submit the BRSR, now needs to obtain reasonable assurance for the BRSR Core and report on its value chain's ESG performance. This may increase compliance costs but enhances investor confidence and transparency.",
      "customer_communication": "Dear Investor,\n\nSEBI has updated its Business Responsibility and Sustainability Reporting (BRSR) requirements.  A new BRSR Core, focusing on key ESG metrics, now requires assurance from a select group of top listed companies and disclosures from their value chain. This enhanced transparency aims to improve investor decision-making.  For details on the phased implementation and its impact, please refer to the SEBI circular on our website.",
      "circular_link": "https://www.sebi.gov.in/sebi_data/attachdocs/jul-2023/1689166456465.pdf",
      "department": "Corporation Finance Department",
      "original_circular_link": "https://www.sebi.gov.in/legal/circulars/may-2021/business-responsibility-and-sustainability-reporting-by-listed-entities_50096.html",
      "original_circular_date": "10/May/2021",
      "original_circular_highlights": [
        "Prescribed the Business Responsibility and Sustainability Report (BRSR) for listed entities.",
        "Included reporting on ESG (Environment, Social, and Governance) parameters.",
        "Mandated BRSR filing for the top 1000 listed entities starting FY 2022-2023.",
        "Provided a guidance note to interpret the scope of disclosures."
      ],
      "what_has_changed": [
        {
          "topic": "BRSR Core and Assurance",
          "changes": [
            "Introduction of BRSR Core, a subset of BRSR for assurance.",
            "Mandated reasonable assurance for BRSR Core for top listed companies on a phased basis."
          ]
        },
        {
          "topic": "ESG Disclosures and BRSR Format",
          "changes": [
            "Required ESG disclosures for the value chain of listed entities.",
            "Updated BRSR format to incorporate new KPIs and industry-specific adjustments."
          ]
        }
      ]
    },
    {
      "date": "30/Jun/2022",
      "circular_no": "SEBI/HO/MIRSD/DoP/P/CIR/2022/91",
      "highlights": [
        "Implementation of DDPI (Demat Debit and Pledge Instruction) has been deferred.",
        "The new effective date for DDPI implementation is September 01, 2022.",
        "The deferment is due to representations from depositories and ongoing system changes."
      ],
      "title": "Extension of DDPI Implementation",
      "summary": "SEBI has extended the implementation date of the Demat Debit and Pledge Instruction (DDPI) circular.\nThe new effective date is September 01, 2022, providing more time for system changes.",
      "detailed_notes": "SEBI circular no. SEBI/HO/MIRSD/DoP/P/CIR/2022/44 dated April 04, 2022, introduced guidelines for the execution of Demat Debit and Pledge Instruction (DDPI) for transfer of securities towards deliveries/settlement obligations and pledging/re-pledging of securities. The implementation date of this circular was July 01, 2022. \n\nHowever, due to representations from depositories and the fact that system changes are still under process, SEBI has decided to extend the implementation date to September 01, 2022. \n\nStock Exchanges and Depositories are directed to inform their members/participants and disseminate this information on their websites.",
      "topics": [
        "DDPI",
        "Settlement",
        "Pledging"
      ],
      "source": "SEBI",
      "example": "Initially, investors and traders were expected to start using the DDPI from July 1st, 2022 for settling trades and pledging securities. However, with this extension, the existing PoA system will continue until September 1st, 2022. After this date,  the DDPI will replace PoA for these specific functions.  This gives brokers and depositories additional time to adjust their systems for DDPI processing.",
      "customer_communication": "Dear Investor/Trader,\n\nThe Securities and Exchange Board of India (SEBI) has postponed the implementation of the Demat Debit and Pledge Instruction (DDPI) to September 1, 2022.  This means you can continue to use the existing Power of Attorney (PoA) process until then. The DDPI, which will eventually replace PoA for settlement and pledging of securities, aims to offer greater transparency and control. We will keep you updated on the transition to DDPI. For any queries, please contact our customer support.",
      "circular_link": "https://www.sebi.gov.in/sebi_data/attachdocs/jun-2022/1656569275163.PDF",
      "department": "Market Intermediaries Regulation and Supervision Department",
      "original_circular_link": "https://www.sebi.gov.in/legal/circulars/apr-2022/execution-of-demat-debit-and-pledge-instruction-ddpi-for-transfer-of-securities-towards-deliveries-settlement-obligations-and-pledging-re-pledging-of-securities_57546.html",
      "original_circular_date": "04/Apr/2022",
      "original_circular_highlights": [
        "Introduced the Demat Debit and Pledge Instruction (DDPI).",
        "DDPI was to replace the Power of Attorney (PoA) for specific functionalities.",
        "The use of DDPI was meant to improve transparency and mitigate misuse of PoA.",
        "The implementation date was set for July 1, 2022."
      ],
      "what_has_changed": [
        {
          "topic": "DDPI Implementation Delay",
          "changes": [
            "The implementation date of DDPI has been postponed from July 1, 2022 to September 1, 2022.",
            "The delay is due to system updates being underway at depositories and representations received from them."
          ]
        }
      ]
    }
  ];

  const [selectedCard, setSelectedCard] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(today); // Default to today's date
  const [endDate, setEndDate] = useState(today); // Default to today's date

  const [selectedSector, setSelectedSector] = useState("All Sectors");
  const [selectedIndustry, setSelectedIndustry] = React.useState("Select");
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };
  const [loading1, setLoading1] = useState(true);

  const handleLoad = () => {
    setLoading1(false);
  };
  // Filtered Data Logic
  // const filteredData = content.filter((entry) => {
  //   const matchesSearchTerm =
  //     !searchTerm || entry.title.toLowerCase().includes(searchTerm.toLowerCase());

  //   const withinDateRange =
  //     (!startDate || new Date(entry.date) >= new Date(startDate)) &&
  //     (!endDate || new Date(entry.date) <= new Date(endDate));

  //   return matchesSearchTerm && withinDateRange;
  // });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleSave = (e) => {
    e.stopPropagation();
    // Save action logic (e.g., API call, localStorage update)
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const filteredData = content;

  const sendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    setMessages([...messages, userMessage]);
    setInput("");
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            {
              role: "user",
              content: `${input} use the following data to ans ${JSON.stringify(
                selectedCard
              )} `,
            },
          ],
          // max_tokens: maxResponseTokens,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, // Replace with your actual API key
          },
        }
      );

      const botMessage = {
        sender: "bot",
        text: response?.data.choices[0].message.content.trim(),
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error:", error);
      // Provide more detailed error messages based on status
      const errorMessage = {
        sender: "bot",
        text: "Something went wrong. Please try again later.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };
useEffect(()=>{

},[selectedCard])
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* Left side: List of cards */}
      {!selectedCard && (
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            height: "80vh",
            overflow: "auto",
            background: "#f6f6f6",
          }}
        >
          {/* Search and Filters */}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginBottom: "20px",
              flexDirection: "row",
              width: "40%",
              marginLeft: "auto", // This pushes the box to the right
              justifyContent: "flex-end",
              alignItems: "center", // Ensures vertical alignment for elements
            }}
          >
            {/* Search */}
            <TextField
              label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ flex: 1, minWidth: 300 }}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Start Date */}
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              sx={{ flex: 1, minWidth: 160 }}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />

            {/* End Date */}
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              sx={{ flex: 1, minWidth: 160 }}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />

            {/* Filter by Sector */}
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel>Department</InputLabel>
              <Select
                value={selectedSector}
                onChange={(e) => setSelectedSector(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <FilterListIcon />
                  </InputAdornment>
                }
                size="small"
                label="Department"
              >
                <MenuItem value="All Sectors">All Departments</MenuItem>
                <MenuItem value="Policy">Policy</MenuItem>
                <MenuItem value="Finance">Finance</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ minWidth: 250 }}>
              <InputLabel id="industry-select-label">Saved Searches</InputLabel>
              <Select
                labelId="industry-select-label"
                id="industry-select"
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <FilterListIcon />
                  </InputAdornment>
                }
                value={selectedIndustry}
                onChange={handleIndustryChange}
                label="Select Industry"
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Tech and Energy Growth">
                  Tech and Energy Growth
                </MenuItem>
                <MenuItem value="Midcap Innovators">Midcap Innovators</MenuItem>
                <MenuItem value="Dynamic Financials and Health">
                  Dynamic Financials and Health
                </MenuItem>
                <MenuItem value="High-Yield Energy and Utilities">
                  High-Yield Energy and Utilities
                </MenuItem>
                <MenuItem value="Diverse Midcap Opportunities">
                  Diverse Midcap Opportunities
                </MenuItem>
                <MenuItem value="Financial and IT Leaders">
                  Financial and IT Leaders
                </MenuItem>
                <MenuItem value="Healthcare and Consumer Staples">
                  Healthcare and Consumer Staples
                </MenuItem>
                <MenuItem value="Growth and Stability Mix">
                  Growth and Stability Mix
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Render Cards */}
          {filteredData.length > 0 ? (
            filteredData.map((entry, index) => (
              <Box
                key={index}
                onClick={() => {
                  setSelectedCard(entry);
                }}
                sx={{
                  cursor: "pointer",
                  marginBottom: "20px",
                  borderLeft:
                    selectedCard?.title === entry.title
                      ? "5px solid #007680"
                      : "none", // Add border if selected
                  borderRadius: "18px", // Optional: Add some border radius for styling
                  // padding: "10px", // Optional: Padding for visual spacing
                }}
              >
                <DataCard data={entry} />
              </Box>
            ))
          ) : (
            <Box>No data found for the selected filters.</Box>
          )}
        </Box>
      )}

      {/* Right side: Detailed View */}
      {selectedCard && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
          }}
        >
          {/* Go Back Button */}
          <Box sx={{ width: "50%" }}>
            <Box
              sx={{
                padding: "10px",
                borderBottom: "1px solid #ddd",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <KeyboardBackspaceIcon
                  onClick={() => {
                    setSelectedCard(null);
                    setLoading1(true);
                    setMessages([]);
                  }}
                  sx={{
                    marginRight: "8px",
                    fontSize: "18px",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                />

                <Box
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginLeft: "20px",
                    marginTop: "-20px",
                    color: "#131c32",
                  }}
                >
                  {selectedCard.title}
                </Box>
              </Box>
            </Box>

            <div style={{ position: "relative", height: "calc(100vh - 50px)" }}>
      {loading1 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      )}

      <iframe
        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
          selectedCard.circular_link
        )}`}
        title="Circular PDF"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        onLoad={handleLoad}
      ></iframe>

{/* <object
  data={selectedCard.circular_link}
  type="application/pdf"
  style={{
    width: "100%",
    height: "100%",
    border: "none",
  }}
>
  <p>Your browser does not support viewing PDF files. <a href={selectedCard.circular_link} target="_blank" rel="noopener noreferrer">Download the PDF</a></p>
</object> */}
    </div>
            {/* <Box
        component="iframe"
        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
          selectedCard.circular_link
        )}`}
        title="Circular PDF"
        sx={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      /> */}
          </Box>

          {/* Circular Details */}
          <Box
            sx={{
              padding: "20px",
              background: "#fff",
              borderLeft: "1px solid #ddd",
              borderRadius: "8px",
              width: "50%",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              height:'100vh',
              overflow:'auto'
            }}
          >
            {/* Circular Number */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "16px",
                color: "#2E3B4E",
              }}
            >
              Circular No: {selectedCard.circular_no}
            </Typography>

            {/* Source and Date */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
                color: "#555",
              }}
            >
              <Typography variant="body1">
                <strong>Source:</strong> {selectedCard.source}
              </Typography>
              <Typography variant="body1">
                <strong>Date:</strong> {selectedCard.date}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              {/* AI Summary Highlights */}
              <Card
                sx={{
                  width: "100%",
                  padding: "16px",
                  backgroundColor: "#F9FAFB",
                  borderRadius: "12px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "#34495E",
                    marginBottom: "8px",
                  }}
                >
                  AI-Generated Summary
                </Typography>
                <ul
                  style={{
                    margin: "0",
                    padding: "0 0 0 20px",
                    color: "#333",
                    lineHeight: "1.6",
                  }}
                >
                  {selectedCard.highlights.map((highlight, idx) => (
                    <li key={idx} style={{ marginBottom: "8px" }}>
                      {highlight}
                    </li>
                  ))}
                </ul>
              </Card>

              {/* Detailed Notes */}
              {selectedCard?.detailed_notes && (
                <Accordion sx={{width:'100%'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "#2C3E50" }}
                    >
                      Detailed Notes
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        margin: "0",
                        padding: "0 0 0 20px",
                        color: "#444",
                        lineHeight: "1.6",
                      }}
                    >
                      {selectedCard.detailed_notes}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {selectedCard?.example && (
                <Accordion sx={{width:'100%'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "#2C3E50" }}
                    >
                      Illustrative Example
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        margin: "0",
                        padding: "0 0 0 20px",
                        color: "#444",
                        lineHeight: "1.6",
                      }}
                    >
                      {selectedCard?.example}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {/* Original Circular Highlights */}
              {selectedCard.original_circular_highlights && (
                <Accordion sx={{width:'100%'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "#34495E" }}
                    >
                      Key Points from Original Circular
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul
                      style={{
                        margin: "0",
                        padding: "0 0 0 20px",
                        color: "#333",
                        lineHeight: "1.6",
                      }}
                    >
                      {selectedCard.original_circular_highlights.map(
                        (highlight, idx) => (
                          <li key={idx} style={{ marginBottom: "8px" }}>
                            {highlight}
                          </li>
                        )
                      )}
                    </ul>
                    <Box sx={{ marginTop: "16px", color: "#34495E" }}>
                      {selectedCard.original_circular_date && (
                        <Typography
                          variant="body2"
                          sx={{ fontStyle: "italic", marginBottom: "8px" }}
                        >
                          Original Circular Date:{" "}
                          {selectedCard.original_circular_date}
                        </Typography>
                      )}
                      {selectedCard.original_circular_link && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          component="a"
                          href={selectedCard.original_circular_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Original Circular
                        </Typography>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}

              {/* What Has Changed */}
              {selectedCard.what_has_changed && (
  <Accordion sx={{width:'100%'}}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", color: "#34495E" }}
      >
        What has changed in this circular?
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {selectedCard.what_has_changed.map((item, index) => (
        <div key={index} style={{ marginBottom: "16px" }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", marginBottom: "8px", color: "#2C3E50" }}
          >
            {item.topic}
          </Typography>
          <ul
            style={{
              margin: "0",
              padding: "0 0 0 20px",
              color: "#333",
              lineHeight: "1.6",
            }}
          >
            {item.changes.map((change, idx) => (
              <li key={idx} style={{ marginBottom: "8px" }}>
                {change}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </AccordionDetails>
  </Accordion>
)}

            </Box>

            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                textTransform: "none",
                background: "#6EC1E4",
                display: "block",
                margin: "auto",
                fontWeight: "550",
                "&:hover": {
                  background: "#6EC1E4", // Keep the background the same on hover
                  boxShadow: "none", // Remove any shadow effect on hover
                },
              }}
            >
              Save
            </Button>

            <Paper
              elevation={3}
              sx={{
                borderRadius: "12px",
                width: "95%",
                height: "55vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
                margin: "auto",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  backgroundColor: "#6EC1E4",
                  // padding: "10px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  position: "relative",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={botIcon}
                    alt="ChatBot"
                    style={{
                      height: "40px",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#FFF",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Krita-AI
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  padding: "16px",
                  backgroundColor: "#F4F6F8",
                  borderRadius: "12px",
                  boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.5)",
                  // marginBottom: "16px",
                }}
              >
                {messages?.length === 0 && (
                  <Box
                    sx={{
                      fontFamily: "Arial, sans-serif", // Change the font family
                      fontSize: "14px", // Adjust the font size
                      color: "#333", // Change the text color
                      textAlign: "center", // Center the text
                      marginTop: "10vh", // Move it down a bit
                      transform: "translateY(-50%)", // Center it vertically
                      letterSpacing: "1px", // Add some letter spacing
                      // textTransform: "uppercase", // Uppercase the text
                      // fontWeight: "bold", // Make it bold
                      opacity: 0.8, // Adjust the opacity
                      transition: "opacity 0.3s ease", // Add a fade-in transition
                      "&:hover": {
                        opacity: 1, // Fade in on hover
                      },
                    }}
                  >
                    Ask our AI about this circular. You can also create
                    communication assets.
                  </Box>
                )}
                <List sx={{ padding: 0 }}>
                  {messages.map((msg, index) => (
                    <React.Fragment key={index}>
                      {msg.sender === "user" ? (
                        <UserMessageCard text={msg.text} />
                      ) : (
                        <BotMessageCard text={msg.text} />
                      )}
                      <Divider sx={{ marginY: 1 }} />
                    </React.Fragment>
                  ))}
                </List>
                {loading && (
                  <CircularProgress
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#438BF5",
                      background:
                        "conic-gradient(from 90deg at 50% 50%, rgba(21, 157, 255, 0) 0deg, #159DFF 359.96deg, rgba(21, 157, 255, 0) 360deg)",
                    }}
                    thickness={5}
                  />
                )}
              </Box>

              <Box
                sx={{
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "5px",
                  mb: "5px",
                }}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Ask a question..."
                  value={input}
                  size="small"
                  sx={{
                    border: "2px solid #6EC1E4",
                    borderRadius: "12px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={sendMessage}
                  sx={{
                    marginRight: "8px",
                    color: "#fff",
                    backgroundColor: "#6EC1E4",

                    padding: "7px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    "&:hover": {
                      backgroundColor: "#6EC1E4", // Maintain the same background color on hover
                      color: "#fff", // Maintain the same text color on hover
                    },
                  }}
                  endIcon={<SendIcon sx={{ color: "#FFF" }} />}
                >
                  Send
                </Button>
              </Box>
            </Paper>

            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={handleCloseSnackbar} severity="success">
                Circular has been successfully saved!
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CircularTab;
