import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ShareIcon from "@mui/icons-material/Share";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { teal, grey } from "@mui/material/colors";
import { Button, Grid, IconButton, Link, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
// import InfoIcon from "@mui/icons-material/Info";
import { Close as CloseIcon } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import SNCL_Data from "./SNCL"; // Assuming these are imported correctly
import ZETWORK_Data from "./ZETWORK";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // Filled version
import ThumbDownIcon from "@mui/icons-material/ThumbDown"; // Filled version
import SendIcon from "@mui/icons-material/Send";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Card, Chip } from "@mui/material";
import stockimage from "./stockimage.jpg";
import stockimage2 from "./stockmarket2.jpg";
import stockimage3 from "./stockimage3.jpg";
import { alpha } from "@mui/material/styles"; // Import alpha for lighter background colors
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

const DynamicCard = ({ source, timeAgo, title, description, imageUrl, sourceLink, chipsData, onShare, onViewArticle }) => {
  return (
    <Card
      sx={{
        minHeight: "140px",
        borderRadius: "16px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        marginBottom: "24px",
        padding: "20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        transition: "transform 0.3s, box-shadow 0.3s",
        // '&:hover': {
        //   transform: 'translateY(-5px)',
        //   boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
        // }
      }}
    >
      {/* Left Section: Title, Description */}
      <Box sx={{ flex: 1 }}>
        {/* Title and Chips */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2E3B4E", flex: 1, marginRight: "16px" }}>
            {title}
          </Typography>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {/* Chips */}
            {chipsData.map((chip, index) => (
              <Tooltip key={index} title={chip.label} arrow>
                <Chip
                  label={chip.value}
                  sx={{
                    backgroundColor: chip.color ? alpha(chip.color, 0.15) : "#e0e0e0",
                    color: chip.color || "#fff",
                    marginRight: "8px",
                    marginBottom: "4px",
                    fontWeight: "bold",
                    // borderRadius: '8px',
                    padding: "0 8px",
                    border: `1px solid ${chip.color || "#e0e0e0"}`,
                    cursor: "pointer", // Optional, to show it's interactive
                  }}
                />
              </Tooltip>
            ))}
          </Box>
        </Box>

        {/* Source, Time and Description */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginBottom: "16px" }}>
          <Box>
            <Typography sx={{ fontSize: "12px", color: "#999", fontWeight: "500", marginBottom: "8px" }}>
              {source} &bull; {timeAgo}
            </Typography>

            <Typography variant="body2" sx={{ color: "#5F6368", marginBottom: "16px", lineHeight: 1.5, width: "85%" }}>
              {description}
            </Typography>
          </Box>

          {/* Image */}
          <Box sx={{ flex: "0 0 150px", marginLeft: "16px" }}>
            <img
              src={imageUrl}
              alt="Article"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "12px",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", justifyContent: "left" }}>
            <ShareIcon sx={{ color: "#6EC1E4", cursor: "pointer", padding: "10px" }} />
            <OpenInNewIcon
              sx={{ color: "#6EC1E4", cursor: "pointer", padding: "10px" }}
              onClick={() => {
                window.open(sourceLink, "_blank", "noopener,noreferrer");
              }}
            />
          </Box>

          <FeedbackComponent />
        </Box>
      </Box>
    </Card>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
    },
    background: {
      default: grey[100],
      paper: "#fff",
    },
    text: {
      primary: grey[900],
    },
  },
  typography: {
    h6: {
      fontWeight: 600,
      fontSize: "1.25rem", // 20px
    },
    body1: {
      fontSize: "1rem", // 16px
    },
    body2: {
      fontSize: "0.875rem", // 14px
      color: grey[600],
    },
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box
          sx={{
            p: 3,
            borderRadius: "8px",
            backgroundColor: "#f5f5f5", // Light background for better contrast
            boxShadow: 3, // Adds a subtle shadow for depth
            // transition: "background-color 0.3s ease", // Smooth transition for background color
            // "&:hover": {
            //   backgroundColor: "#e0ebeb", // Slightly darker background on hover
            // },
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const getSeverityIcon = (severity) => {
  switch (severity) {
    case "High":
      return <ErrorIcon sx={{ color: "red" }} />;
    case "Medium":
      return <WarningIcon sx={{ color: "orange" }} />;
    case "Low":
      return <CheckCircleIcon sx={{ color: "green" }} />;

    default:
      return null;
  }
};

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "600", // Medium-bold for better emphasis
  fontSize: "14px", // Readable size for tab labels
  color: "#000", // Default color
  margin: "5px",
  borderRadius: "4px",
  "&:hover": {
    // border: "2px solid #6EC1E4", // Light blue for hover
    color: "#6EC1E4", // Primary blue text on hover
  },
  "&.Mui-selected": {
    borderBottom: "4px solid #6EC1E4", // Primary blue for selected tab
    color: "#6EC1E4", // White text for selected tab
  },
}));

const CustomTabs = styled(Tabs)(({ theme }) => ({
  // marginBottom: theme.spacing(2),
  // borderBottom: `2px solid #6EC1E4`, // Dark blue bottom border
  background: "#FFF",
  paddingBottom: "20px",
  ".MuiTabs-flexContainer": {
    gap: theme.spacing(2), // Adds spacing between tabs
  },
}));
const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const FeedbackBox = ({ open, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    onSubmit(feedback);
    setFeedback("");
  };

  return (
    open && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "-150px", // Adjust this value as needed
          right: "0",
          width: "300px",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          zIndex: 1000, // Ensures it appears above other content
        }}
      >
        <TextField variant="outlined" fullWidth placeholder="Enter your feedback..." value={feedback} onChange={(e) => setFeedback(e.target.value)} sx={{ marginBottom: "8px" }} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleSubmit}
            endIcon={<SendIcon />}
            sx={{
              marginRight: "8px",
              color: "#fff",
              background: "#6EC1E4",
              "&:hover": {
                backgroundColor: "#6EC1E4", // Maintain the same background color on hover
                color: "#fff", // Maintain the same text color on hover
              },
            }}
          >
            Send
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Paper>
    )
  );
};
const FeedbackComponent = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [likeFilled, setLikeFilled] = useState(false);
  const [dislikeFilled, setDislikeFilled] = useState(false);

  const handleFeedbackOpen = (type) => {
    setFeedbackType(type);
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setLikeFilled(false);
    setDislikeFilled(false);
  };

  const handleFeedbackSubmit = (feedback) => {
    console.log(`Feedback (${feedbackType}): ${feedback}`);
    setFeedbackOpen(false);
    if (feedbackType === "like") {
      setLikeFilled(false);
    } else if (feedbackType === "dislike") {
      setDislikeFilled(false);
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end" sx={{ position: "relative", padding: "20px" }}>
      <Tooltip title="Like">
        <IconButton
          onClick={() => {
            setLikeFilled(true);
            handleFeedbackOpen("like");
          }}
        >
          {likeFilled ? <ThumbUpIcon color="success" /> : <ThumbUpOffAltIcon color="success" />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Dislike">
        <IconButton
          onClick={() => {
            setDislikeFilled(true);
            handleFeedbackOpen("dislike");
          }}
        >
          {dislikeFilled ? <ThumbDownIcon color="error" /> : <ThumbDownOffAltIcon color="error" />}
        </IconButton>
      </Tooltip>
      <FeedbackBox open={feedbackOpen} onClose={handleFeedbackClose} onSubmit={handleFeedbackSubmit} />
    </Box>
  );
};

const RenderContent = ({ content }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const images = [
    stockimage, // Replace with your actual image paths
    stockimage2,
    stockimage3,
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    impactHorizon: [],
    categoryType: [],
    stockPriceEffect: [],
  });

  const toggleShowContent = () => {
    setShowFullContent(!showFullContent);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength && !showFullContent) {
      return (
        <>
          {text.substring(0, maxLength)}
          <span style={{ cursor: "pointer", color: "#6EC1E4" }} onClick={toggleShowContent}>
            ...more
          </span>
        </>
      );
    } else {
      return (
        <>
          {text}
          {showFullContent && (
            <span style={{ cursor: "pointer", color: "#6EC1E4" }} onClick={toggleShowContent}>
              ...less
            </span>
          )}
        </>
      );
    }
  };

  var currentImageIndex = 0;
  const getRandomImage = () => {
    const image = images[currentImageIndex];
    currentImageIndex = (currentImageIndex + 1) % images.length; // Move to the next image, loop back to start
    return image;
  };
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };
  
  const handleFilterSelect = (category, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: prevFilters[category]?.includes(value)
        ? prevFilters[category].filter((item) => item !== value)
        : [...(prevFilters[category] || []), value], // Add safely even if undefined
    }));
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  // Normalize function for values
  const normalize = (value) => value?.toLowerCase().replace(/\s|-|_/g, "");
  
  const filteredEntries = content?.data?.entries?.filter((entry) => {
    const normalizedImpactHorizon = normalize(entry?.classification?.find((c) => c.key === "impact_horizon")?.value);
    const normalizedCategoryType = normalize(entry?.classification?.find((c) => c.key === "category")?.value);
    const normalizedStockPriceEffect = normalize(entry?.classification?.find((c) => c.key === "stock_price_effect")?.value);
  
    // Dynamic matching for impactHorizon
    const impactHorizonMatches = selectedFilters.impactHorizon?.length
      ? selectedFilters.impactHorizon.some((selected) => normalize(selected) === normalizedImpactHorizon)
      : true; // If no filter is selected for impactHorizon, match all.
  
    // Dynamic matching for categoryType
    const categoryTypeMatches = selectedFilters.categoryType?.length
      ? selectedFilters.categoryType.some((selected) => normalize(selected) === normalizedCategoryType)
      : true; // If no filter is selected for categoryType, match all.
  
    // Dynamic matching for stockPriceEffect
    const stockPriceEffectMatches = selectedFilters.stockPriceEffect?.length
      ? selectedFilters.stockPriceEffect.some((selected) => normalize(selected) === normalizedStockPriceEffect)
      : true; // If no filter is selected for stockPriceEffect, match all.
  
    return impactHorizonMatches && categoryTypeMatches && stockPriceEffectMatches;
  })
    .filter((entry) =>
      entry.label?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

  return (
    <>
      {content.source && (
        <Box display="flex" justifyContent="flex-end">
          <Link href={content.source.link} target="_blank" rel="noopener" sx={{ color: "#6EC1E4", fontSize: "14px", marginBottom: "10px" }}>
            Source: {content.source.label}
          </Link>
        </Box>
      )}
      {content.objectType === "paragraph" && (
        <Typography variant="body1" gutterBottom>
          {content.data}
        </Typography>
      )}
      {content.objectType === "dictionary" && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {content.data.title}
          </Typography>
          <dl>
            {content.data.entries.map((entry) => (
              <Box key={entry.key} mb={2}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography variant="body2" component="dt" fontWeight="bold">
                    {entry.label}:
                  </Typography>
                  {entry?.riskSeverity && entry.riskSeverity !== "NA" && entry.riskSeverity !== "Low" && (
                    //    <Chip
                    //    icon={getSeverityIcon(entry.riskSeverity)}
                    //    size="small"
                    //    style={{ borderRadius: 4, fontSize: '0.75rem' }} // Smaller chip size and text
                    //    // variant="outlined"
                    //    color={entry.riskSeverity === 'High' ? 'error' : entry.riskSeverity === 'Medium' ? 'warning' : 'info'}
                    //  />

                    <Tooltip
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                            color: "#333",
                            backgroundColor: "#ccc",
                            // border: '1px solid #808080',
                            borderRadius: "4px",
                            padding: "4px 8px",
                            cursor: "pointer",
                          }}
                        >
                          <span>Dismiss</span>
                          <CloseIcon sx={{ height: "15px", color: "red" }} />
                        </Box>
                      }
                      arrow
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#ccc", // Set tooltip background to transparent
                            // boxShadow: 'none', // Remove default shadow
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#ccc", // Color of the tooltip arrow
                          },
                        },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* Severity Icon */}
                        {getSeverityIcon(entry.riskSeverity)}
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Typography variant="body1" component="dd">
                  {entry.value}
                </Typography>
              </Box>
            ))}
          </dl>
        </Box>
      )}
      {content.objectType === "table" && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {content.data.title}
          </Typography>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                {content.data.columns.map((column) => (
                  <th key={column.key} style={{ borderBottom: "2px solid #ddd", padding: "8px", textAlign: "left" }}>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {content.data.rows.map((row) => (
                <tr key={row.id}>
                  {content.data.columns.map((column) => (
                    <td key={column.key} style={{ borderBottom: "1px solid #ddd", padding: "8px", cursor: "pointer" }}>
                      {truncateText(row[column.key], 60)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}

      {content.objectType === "cards" && (
    <Box>
    {/* Filter and Search Bar */}
    <Box sx={{ display: 'flex', alignItems: 'right', marginBottom: '16px',justifyContent:'flex-end' }}>
      <IconButton onClick={handleFilterClick}>
        <FilterListIcon />
      </IconButton>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search by title"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ marginLeft: '8px',justifyContent:'flex-end'  }}
      />
      <IconButton sx={{ marginLeft: '8px',justifyContent:'flex-end'   }}>
        <SearchIcon />
      </IconButton>
    </Box>

    {/* Filter Menu */}

<Dialog
  open={Boolean(anchorEl)}
  onClose={handleCloseFilterMenu}
  fullWidth
  maxWidth="md"
>
  <DialogTitle>
    Filter Options
    <IconButton
      aria-label="close"
      onClick={handleCloseFilterMenu}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>

  <DialogContent sx={{ overflow: "auto" }}>
    {/* Stock Price Effect */}
    <Typography variant="h6" sx={{ padding: "4px 8px" }}>
      Stock Price Effect
    </Typography>
    <Grid container spacing={1} sx={{ padding: "4px 8px" }}>
      {["Very Bullish", "Bullish", "Neutral", "Bearish", "Very Bearish"].map((item) => (
        <Grid item xs={2} key={item}>
          <MenuItem onClick={() => handleFilterSelect("stockPriceEffect", item)}>
            <Chip
              label={item}
              sx={{
                backgroundColor: selectedFilters.stockPriceEffect?.includes(item) ? "#6EC1E4" : "#e0e0e0",
                color: selectedFilters.stockPriceEffect?.includes(item) ? "#fff" : "#000",
                width: "100%",
              }}
            />
          </MenuItem>
        </Grid>
      ))}
    </Grid>

    {/* Impact Horizon */}
    <Typography variant="h6" sx={{ padding: "4px 8px" }}>
      Impact Horizon
    </Typography>
    <Grid container spacing={1} sx={{ padding: "4px 8px" }}>
      {["Short Term", "No Impact", "Long Term"].map((item) => (
        <Grid item xs={3} key={item}>
          <MenuItem onClick={() => handleFilterSelect("impactHorizon", item)}>
            <Chip
              label={item}
              sx={{
                backgroundColor: selectedFilters.impactHorizon?.includes(item) ? "#6EC1E4" : "#e0e0e0",
                color: selectedFilters.impactHorizon?.includes(item) ? "#fff" : "#000",
                width: "100%",
              }}
            />
          </MenuItem>
        </Grid>
      ))}
    </Grid>

    {/* Category Type */}
    <Typography variant="h6" sx={{ padding: "4px 8px" }}>
      Category Type
    </Typography>
    <Grid container spacing={1} sx={{ padding: "4px 8px" }}>
      {[
        "Products & Services",
        "Capital/ Expansion",
        "Management",
        "Company Operations",
        "Financial Performance",
        "Corporate Developments",
        "Research Analysis",
        "Analyst Rating",
        "Industry News",
        "Earnings",
      ].map((item) => (
        <Grid item xs={3} key={item}>
          <MenuItem onClick={() => handleFilterSelect("categoryType", item)}>
            <Chip
              label={item}
              sx={{
                backgroundColor: selectedFilters.categoryType?.includes(item) ? "#6EC1E4" : "#e0e0e0",
                color: selectedFilters.categoryType?.includes(item) ? "#fff" : "#000",
                width: "100%",
              }}
            />
          </MenuItem>
        </Grid>
      ))}
    </Grid>
  </DialogContent>
</Dialog>



    {/* Display Cards */}
    {filteredEntries.map((entry) => (
      <DynamicCard
        key={entry.label}
        sourceLink={entry.sourceLink}
        source={entry.source}
        timeAgo={entry.date}
        title={entry.label}
        description={entry.value}
        imageUrl={getRandomImage()}
        chipsData={entry.classification}
        onShare={() => {}}
        onViewArticle={() => {}}
      />
    ))}
  </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <FeedbackComponent />
      </Box>
    </>
  );
};

const TabsComponent = ({ selectedCompany }) => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch or set data based on selectedCompany
    if (selectedCompany === "SADHANA NITRO CHEM LIMITED") {
      setData(SNCL_Data);
    } else if (selectedCompany === "ZETWERK MANUFACTURING BUSINESSES PRIVATE LIMITED") {
      setData(ZETWORK_Data);
    }
  }, [selectedCompany]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", typography: "body1", bgcolor: "background.default", overflow: "auto" }}>
        {data.length === 0 ? (
          <Paper sx={{ p: 5, textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "300px" }}>
            <IconButton sx={{ fontSize: 60, mb: 2 }} color="primary">
              <SearchIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6" color="text.primary">
              Select a company to start
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Please use the search bar above to lookup a company profile.
            </Typography>
          </Paper>
        ) : (
          <>
            <CustomTabs value={value} onChange={handleChange}>
              {data.map((tab, index) => (
                <CustomTab label={tab.label} key={tab.key} />
              ))}
            </CustomTabs>
            {data.map((tab, index) => (
              <TabPanel value={value} index={index} key={tab.key}>
                {tab.summary && (
                  <CustomPaper style={{ border: "1px solid #E8F5E9" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "26px" }}>
                      {/* Left Table for Odd Rows */}
                      <TableContainer component={Paper} sx={{ borderRadius: "8px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", flex: 1 }}>
                        <Table>
                          <TableBody>
                            {tab.summary
                              .filter((_, index) => index % 2 === 0)
                              .map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ padding: "5px !important" }}>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: "14px" }}>
                                      {item.label}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "5px !important" }}>
                                    <Box sx={{ fontSize: "16px", display: "flex", flexDirection: "row", color: "#808080" }}>
                                      <Box sx={{ marginRight: "15px" }}>{getSeverityIcon(item.riskSeverity)}</Box>
                                      {item.riskSeverity === "Low"
                                        ? "Low Risk"
                                        : item.riskSeverity === "Medium"
                                        ? "Needs Attention"
                                        : item.riskSeverity === "High"
                                        ? "Significant Risk"
                                        : "Unknown Risk"}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {/* Right Table for Even Rows */}
                      <TableContainer component={Paper} sx={{ borderRadius: "8px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", flex: 1 }}>
                        <Table>
                          <TableBody>
                            {tab?.summary
                              ?.filter((_, index) => index % 2 !== 0)
                              ?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ padding: "5px !important" }}>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: "14px" }}>
                                      {item.label}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "5px !important" }}>
                                    <Box sx={{ fontSize: "16px", display: "flex", flexDirection: "row", color: "#808080" }}>
                                      <Box sx={{ marginRight: "15px" }}>{getSeverityIcon(item.riskSeverity)}</Box>
                                      {item.riskSeverity === "Low"
                                        ? "Low Risk"
                                        : item.riskSeverity === "Medium"
                                        ? "Needs Attention"
                                        : item.riskSeverity === "High"
                                        ? "Significant Risk"
                                        : "Unknown Risk"}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </CustomPaper>
                )}

                {tab?.value?.map((content, contentIndex) => (
                  <CustomPaper key={contentIndex} style={{ background: content?.aiGenerated ? "#E8F5E9" : "#fff" }}>
                    <RenderContent content={content} />
                  </CustomPaper>
                ))}
              </TabPanel>
            ))}
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default TabsComponent;
