import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
// import DomainAddIcon from '@mui/icons-material/DomainAdd';
// import { styled, alpha } from '@mui/material/styles';
import logo from './krita.svg';
import Box from '@mui/material/Box';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';


const Header = ({ onCompanySelect }) => {
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const items = [
    { name: 'ZETWERK MANUFACTURING BUSINESSES PRIVATE LIMITED' },
    { name: 'SADHANA NITRO CHEM LIMITED' },
  ];

  const handleOnSelect = (item) => {
    localStorage.setItem('selectedCompany', item?.name);
    onCompanySelect(item?.name); // Propagate selected company to parent component
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const handleUpload = () => {
    // Handle file upload logic here
    console.log(selectedFiles);
    handleClose();
  };

  return (


       
        <Box
          display="flex"
          justifyContent="center"
          flexGrow={1}
          sx={{ margin: '0 16px' }}
        >
          <Box
            sx={{
              width: { xs: '100%', sm: 400 },
            }}
          >
            <ReactSearchAutocomplete
              items={items}
              onSelect={handleOnSelect}
              autoFocus
              formatResult={(item) => (
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
              )}
            />
          </Box>
          </Box>



  );
};

export default Header;
