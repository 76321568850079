const ZETWORK_Data=[
  {
    "key": "borrower_profile",
    "label": "Company Profile",
    "value": [
      {
        "objectType": "dictionary",
        "data": {
          "title": "Company Factsheet",
          "entries": [
            {
              "key": "legal_name",
              "label": "Legal Name",
              "value": "ZETWERK MANUFACTURING BUSINESSES PRIVATE LIMITED"
            },
            {
              "key": "cin",
              "label": "CIN",
              "value": "U74999KA2017PTC128777"
            },
            {
              "key": "authorised_capital",
              "label": "Authorised Capital (Crore)",
              "value": "43.07"
            },
            {
              "key": "paid_up_capital",
              "label": "Paid Up Capital (Crore)",
              "value": "40.27"
            },
            {
              "key": "sum_of_charges",
              "label": "Sum of Charges (Crore)",
              "value": "4,466.68"
            },
            {
              "key": "company_status",
              "label": "Company Status",
              "value": "Active"
            },
            {
              "key": "active_compliance",
              "label": "Active Compliance",
              "value": "Active Compliant"
            },
            {
              "key": "entity_type",
              "label": "Type of Entity",
              "value": "Private Limited Indian Non-Government Company"
            },
            {
              "key": "listing_status",
              "label": "Listing Status",
              "value": "Unlisted"
            },
            {
              "key": "incorporation_date",
              "label": "Date of Incorporation",
              "value": "6 Dec, 2017"
            },
            {
              "key": "last_agm_date",
              "label": "Date of Last AGM",
              "value": "30 Sep, 2023"
            },
            {
              "key": "industry",
              "label": "Industry",
              "value": "Information Technology"
            },
            {
              "key": "segment",
              "label": "Segment(s)",
              "value": "Electronic Equipment and Components Manufacturers"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Overview",
          "entries": [
            {
              "key": "company_description",
              "label": "Company Description",
              "value": "Zetwerk Manufacturing Businesses Private Limited (Zetwerk) is a manufacturing company that provides services to various sectors, including steel fabrication, precision parts manufacturing, electronic products, aerospace and defense, and trading of metals. The company has been in operation for just over six years and has grown exponentially in the past three fiscals. Zetwerk has a large customer base that includes both domestic and international clients.",
              "riskSeverity": "NA"
            },
            {
              "key": "industry_analysis",
              "label": "Industry Analysis",
              "value": "The manufacturing industry is highly competitive and subject to various risks, including fluctuations in demand, commodity prices, and labor costs. The industry is also characterized by high capital intensity and a need for continuous innovation to remain competitive. Zetwerk operates in a diverse set of industries, which helps to mitigate some of the risks associated with any single sector. However, the company's dependence on a large number of suppliers and contractors exposes it to risks of supply chain disruptions and quality control issues.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "aiGenerated": true,
        "source": {
          "link": "https://www.youtube.com/watch?v=hB7Jze6yB4Y",
          "label": "Investor Call Transcript"
        },
        "data": {
          "title": "Company Overview",
          "columns": [
            {
              "key": "aspect",
              "label": "Aspect"
            },
            {
              "key": "description",
              "label": "Description"
            }
          ],
          "rows": [
            {
              "aspect": "Business Model",
              "description": "Managed marketplace connecting OEMs and EPC customers with SMEs for contract manufacturing."
            },
            {
              "aspect": "Services Offered",
              "description": "Fabrication, machining, casting, forging, and other manufacturing processes. Helps procure customized products not available off-the-shelf."
            },
            {
              "aspect": "Target Customers",
              "description": "Original Equipment Manufacturers (OEMs), Engineering, Procurement and Construction (EPC) companies, customers across industries like precision manufacturing, aerospace, defense, renewables, consumer electronics, oil & gas, and infrastructure."
            },
            {
              "aspect": "Value Proposition",
              "description": "Solving reliability issues in manufacturing supply chains, curating and vetting supplier network, ensuring quality and on-time delivery through technology, integrating software, logistics, and working capital solutions for suppliers."
            },
            {
              "aspect": "Growth Strategy",
              "description": "Leveraging 'Make in India' initiatives and global supply chain shifts away from China, focusing on large enterprise customers, expanding geographically."
            },
            {
              "aspect": "Key Stats",
              "description": "Founded in 2018, raised $9 million Series A, valued at $2.8 billion, presence across India, North America, and Southeast Asia."
            }
          ]
        }
      },
      {
        "objectType": "table",
        "aiGenerated": true,
        "source": {
          "link": "https://www.youtube.com/watch?v=hB7Jze6yB4Y",
          "label": "Investor Call Transcript"
        },
        "data": {
          "title": "Financial Performance",
          "columns": [
            {
              "key": "metric",
              "label": "Metric"
            },
            {
              "key": "value",
              "label": "Value"
            }
          ],
          "rows": [
            {
              "metric": "Valuation",
              "value": "$2.8 billion"
            },
            {
              "metric": "Revenue Growth (First 4 Months)",
              "value": "From 1 crore to 6 crore"
            },
            {
              "metric": "Month-on-Month Growth (Early Stages)",
              "value": "20-30%"
            },
            {
              "metric": "Export Revenue Contribution",
              "value": "1/3 of total revenue"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "aiGenerated": true,
        "source": {
          "link": "https://www.youtube.com/watch?v=hB7Jze6yB4Y",
          "label": "Investor Call Transcript"
        },
        "data": {
          "title": "Market Opportunities",
          "columns": [
            {
              "key": "opportunity",
              "label": "Opportunity"
            },
            {
              "key": "description",
              "label": "Description"
            }
          ],
          "rows": [
            {
              "opportunity": "Manufacturing in India",
              "description": "Seen as a 'once in a generational opportunity' due to factors like 'Make in India' initiatives and global supply chain shifts."
            },
            {
              "opportunity": "GST Implementation",
              "description": "Opening up pan-India sourcing and facilitating business growth."
            },
            {
              "opportunity": "Global Manufacturing Shift",
              "description": "Continued growth expected from global companies shifting manufacturing to India."
            }
          ]
        }
      },
      {
        "objectType": "paragraph",
        "aiGenerated": true,
        "source": {
          "link": "https://www.youtube.com/watch?v=hB7Jze6yB4Y",
          "label": "Investor Call Transcript"
        },
        "data": "Zetwerk Manufacturing Businesses Private Limited (ZMBPL) is a technology-driven company offering a manufacturing platform to its clients who are primarily Original Equipment Manufacturers (OEMs) and other engineering companies, to procure and execute manufacturing jobs through a network of Small and Medium Enterprises (SMEs) having those capacities. Since then the company has diversified into contract manufacturing of apparel, consumer goods, and engineering components and has also ventured into turkey projects on a large scale. It caters to various industries such as aerospace, automotive, oil and gas, energy, cement, steel, sugar, infrastructure, material handling, and many more. It works with 100+ customers across India, North America, Middle-East, and South-East Asia. Its clientele includes Afcons, JSW, LT, Tata Steel, Tata Power, and Bhel, among others."
      }
    ]
  },
  {
    "key": "management",
    "label": "Management",
    "summary": [
      {
        "label": "Limited Track Record",
        "riskSeverity": "High"
      },
      {
        "label": "Significant Increase in Remuneration",
        "riskSeverity": "Medium"
      },
      {
        "label": "New Appointments",
        "riskSeverity": "Low"
      }
    ],
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Highlights",
          "entries": [
            {
              "key": "limited_track_record",
              "label": "Limited Track Record",
              "value": "Zetwerk's management has a limited track record of operations, and the company has a complex business model that is exposed to operational risks and counterparty risks. The company also has a muted operating profitability, subdued debt protection metrics, and working capital intensive operations. These factors raise concerns about the company's ability to manage its growth and profitability in the long term.",
              "riskSeverity": "High"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "entries": [
            {
              "key": "significant_increase_in_remuneration",
              "label": "Significant Increase in Remuneration",
              "value": "Director's remuneration - Amrit Acharya: INR 92.88 lakhs for the year ending March 31, 2023, up from INR 51.55 lakhs in 2022.\n Director's remuneration - Srinath Ramakkrushnan: INR 93.80 lakhs for the year ending March 31, 2023, up from INR 47.48 lakhs in 2022.\n The significant increase in remuneration for key management personnel over the past year should be evaluated in terms of company performance and overall financial health.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "title": "Company Management",
          "columns": [
            {
              "key": "name",
              "label": "Name"
            },
            {
              "key": "designation",
              "label": "Present Designation"
            }
          ],
          "rows": [
            {
              "id": 1,
              "name": "SRINATH RAMAKKRUSHNAN",
              "designation": "Director"
            },
            {
              "id": 2,
              "name": "AMRIT PRATIK ACHARYA",
              "designation": "Director"
            },
            {
              "id": 3,
              "name": "PRAYANK SWAROOP",
              "designation": "Nominee Director"
            },
            {
              "id": 4,
              "name": "SHAILESH LAKHANI",
              "designation": "Nominee Director"
            },
            {
              "id": 5,
              "name": "VAIBHAV AGRAWAL",
              "designation": "Nominee Director"
            },
            {
              "id": 6,
              "name": "SANJIV RANGRASS",
              "designation": "Director"
            },
            {
              "id": 7,
              "name": "VINOD KUMAR DASARI",
              "designation": "Director"
            },
            {
              "id": 8,
              "name": "MANIA SARKAR",
              "designation": "Company Secretary"
            },
            {
              "id": 9,
              "name": "PULKIT BHANDARI",
              "designation": "CFO"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "entries": [
            {
              "key": "new_appointments",
              "label": "New Appointments",
              "value": "Mania Sarkar was appointed as the Company Secretary with effect from 10th February 2020. ",
              "riskSeverity": "NA"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "products_and_services",
    "label": "Products & Services",
    "value": [
      {
        "objectType": "table",
        "aiGenerated": true,
        "source": {
          "link": "https://www.zetwerk.com",
          "label": "Website - Zetwork"
        },
        "data": {
          "title": "Products & Services",
          "columns": [
            {
              "key": "products_and_services",
              "label": "Product/Service"
            },
            {
              "key": "market_analysis",
              "label": "Market Analysis"
            },
            {
              "key": "industry_analysis",
              "label": "Industry Analysis"
            }
          ],
          "rows": [
            {
              "products_and_services": "Extrusions",
              "market_analysis": "The global aluminum extrusion market size was valued at USD 150.7 billion in 2022 and is projected to reach USD 220.5 billion by 2030, registering a CAGR of 5.1% during the forecast period. Growing demand for lightweight materials across various industries is expected to drive market growth.",
              "industry_analysis": "The aluminum extrusion industry is a competitive market with a number of large players, including Novelis, Alcoa, and Hydro. The industry is characterized by its high capital intensity, its reliance on specialized equipment, and its focus on innovation. The aluminum extrusion industry is also subject to fluctuations in the price of aluminum, which can impact profitability. The Indian aluminum extrusion market is estimated to be around ₹10,000 crores and is growing at a CAGR of about 10%. The market is driven by factors such as the increasing demand for aluminum extrusions in the construction, automotive, and aerospace industries."
            },
            {
              "products_and_services": "Die Casting",
              "market_analysis": "The global die casting market is estimated to be worth ₹1,443 crores and is expected to grow at a CAGR of 6.5% during the forecast period. The growth of the market is being driven by the increasing demand for die-cast components in the automotive, electronics, and machinery industries.",
              "industry_analysis": "The die casting industry is characterized by its high capital intensity, its reliance on specialized equipment, and its focus on precision. The die casting industry is also subject to fluctuations in the price of metals, which can impact profitability. The Indian die casting market is estimated to be worth ₹1,443 crores and is growing at a CAGR of about 6.5%. The market is driven by factors such as the increasing demand for die-cast components in the automotive, electronics, and machinery industries. The Indian government is also supportive of the die casting industry, offering a number of incentives and subsidies. "
            },
            {
              "products_and_services": "CNC Machining",
              "market_analysis": "The global CNC machining market size was valued at USD 211.5 billion in 2022 and is projected to reach USD 364.8 billion by 2030, registering a CAGR of 7.6% during the forecast period. The growth of the market is being driven by the increasing demand for CNC machined components in the automotive, aerospace, and medical industries.",
              "industry_analysis": "The CNC machining industry is a highly competitive market with a number of large players, including Mazak, Haas, and DMG Mori. The industry is characterized by its high capital intensity, its reliance on skilled labor, and its focus on precision. The CNC machining industry is also subject to fluctuations in the price of metals, which can impact profitability. The Indian CNC machining market is estimated to be worth ₹1,500 crores and is growing at a CAGR of about 10%. The market is driven by factors such as the increasing demand for CNC machined components in the automotive, aerospace, and medical industries. The Indian government is also supportive of the CNC machining industry, offering a number of incentives and subsidies."
            },
            {
              "products_and_services": "Forging",
              "market_analysis": "The global forging market size was valued at USD 181.9 billion in 2022 and is projected to reach USD 295.8 billion by 2030, registering a CAGR of 6.9% during the forecast period. The growth of the market is being driven by the increasing demand for forged components in the automotive, aerospace, and energy industries.",
              "industry_analysis": "The forging industry is a highly capital-intensive industry with a number of large players, including ThyssenKrupp, ArcelorMittal, and Tata Steel. The industry is characterized by its high capital intensity, its reliance on specialized equipment, and its focus on precision. The forging industry is also subject to fluctuations in the price of metals, which can impact profitability. The Indian forging market is estimated to be worth ₹1,000 crores and is growing at a CAGR of about 8%. The market is driven by factors such as the increasing demand for forged components in the automotive, aerospace, and energy industries. The Indian government is also supportive of the forging industry, offering a number of incentives and subsidies."
            },
            {
              "products_and_services": "Injection Molding",
              "market_analysis": "The global injection molding market size was valued at USD 427.8 billion in 2022 and is projected to reach USD 717.6 billion by 2030, registering a CAGR of 7.1% during the forecast period. The growth of the market is being driven by the increasing demand for injection molded components in the automotive, electronics, and packaging industries.",
              "industry_analysis": "The injection molding industry is a highly competitive market with a number of large players, including Milacron, Arburg, and Engel. The industry is characterized by its high capital intensity, its reliance on specialized equipment, and its focus on precision. The injection molding industry is also subject to fluctuations in the price of plastics, which can impact profitability. The Indian injection molding market is estimated to be worth ₹1,500 crores and is growing at a CAGR of about 10%. The market is driven by factors such as the increasing demand for injection molded components in the automotive, electronics, and packaging industries. The Indian government is also supportive of the injection molding industry, offering a number of incentives and subsidies."
            },
            {
              "products_and_services": "Sheet Metal Stamping",
              "market_analysis": "The global sheet metal stamping market size was valued at USD 236.4 billion in 2022 and is projected to reach USD 386.5 billion by 2030, registering a CAGR of 6.8% during the forecast period. The growth of the market is being driven by the increasing demand for sheet metal stamped components in the automotive, aerospace, and construction industries.",
              "industry_analysis": "The sheet metal stamping industry is a highly competitive market with a number of large players, including Amada, Trumpf, and Bystronic. The industry is characterized by its high capital intensity, its reliance on specialized equipment, and its focus on precision. The sheet metal stamping industry is also subject to fluctuations in the price of metals, which can impact profitability. The Indian sheet metal stamping market is estimated to be worth ₹1,200 crores and is growing at a CAGR of about 9%. The market is driven by factors such as the increasing demand for sheet metal stamped components in the automotive, aerospace, and construction industries. The Indian government is also supportive of the sheet metal stamping industry, offering a number of incentives and subsidies."
            },
            {
              "products_and_services": "Prototyping and Pre-Production",
              "market_analysis": "The global prototyping market size was valued at USD 123.4 billion in 2022 and is projected to reach USD 212.5 billion by 2030, registering a CAGR of 7.5% during the forecast period. The growth of the market is being driven by the increasing demand for rapid prototyping services in the automotive, aerospace, and consumer goods industries.",
              "industry_analysis": "The prototyping industry is a highly competitive market with a number of large players, including Stratasys, 3D Systems, and Autodesk. The industry is characterized by its high innovation, its reliance on specialized equipment, and its focus on quick turnaround times. The prototyping industry is also subject to fluctuations in the price of materials, which can impact profitability. The Indian prototyping market is estimated to be worth ₹500 crores and is growing at a CAGR of about 12%. The market is driven by factors such as the increasing demand for rapid prototyping services in the automotive, aerospace, and consumer goods industries. The Indian government is also supportive of the prototyping industry, offering a number of incentives and subsidies."
            },
            {
              "products_and_services": "Investment Casting",
              "market_analysis": "The global investment casting market size was valued at USD 101.5 billion in 2022 and is projected to reach USD 175.6 billion by 2030, registering a CAGR of 7.2% during the forecast period. The growth of the market is being driven by the increasing demand for investment cast components in the automotive, aerospace, and energy industries.",
              "industry_analysis": "The investment casting industry is a highly competitive market with a number of large players, including  The  industry is characterized by its high capital intensity, its reliance on specialized equipment, and its focus on precision. The investment casting industry is also subject to fluctuations in the price of metals, which can impact profitability. The Indian investment casting market is estimated to be worth ₹400 crores and is growing at a CAGR of about 9%. The market is driven by factors such as the increasing demand for investment cast components in the automotive, aerospace, and energy industries. The Indian government is also supportive of the investment casting industry, offering a number of incentives and subsidies."
            },
            {
              "products_and_services": "Assemblies",
              "market_analysis": "The global assembly market size was valued at USD 1,500 billion in 2022 and is projected to reach USD 2,500 billion by 2030, registering a CAGR of 6.5% during the forecast period. The growth of the market is being driven by the increasing demand for complex assemblies in the automotive, aerospace, and electronics industries.",
              "industry_analysis": "The assembly industry is a highly competitive market with a number of large players, including Foxconn, Flex, and Jabil. The industry is characterized by its high volume, its reliance on skilled labor, and its focus on efficiency. The assembly industry is also subject to fluctuations in the price of materials, which can impact profitability. The Indian assembly market is estimated to be worth ₹1,000 crores and is growing at a CAGR of about 10%. The market is driven by factors such as the increasing demand for complex assemblies in the automotive, aerospace, and electronics industries. The Indian government is also supportive of the assembly industry, offering a number of incentives and subsidies."
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://www.zetwerk.com",
          "label": "Website - Zetwork"
        },
        "data": {
          "title": "Products & Services - Factsheet",
          "columns": [
            {
              "key": "products_and_services",
              "label": "Product/Service"
            },
            {
              "key": "description",
              "label": "Description"
            }
          ],
          "rows": [
            {
              "products_and_services": "Extrusions",
              "description": "Zetwerk manufactures high-quality extrusions produced using a range of aluminum alloys, with cross-sections that are as complex as customers require. Zetwerk cuts the extrusions to length and performs all necessary secondary operations such as CNC machining and surface treatment."
            },
            {
              "products_and_services": "Die Casting",
              "description": "Zetwerk provides high-quality die-cast components produced using a range of materials including aluminum, zinc, copper, brass, and bronze alloys. Once a component has been die-cast, it can be further refined with CNC machining. Surface finishes are commonly applied through processes like shot-blasting, texturing, plating, and painting."
            },
            {
              "products_and_services": "CNC Machining",
              "description": "Zetwerk provides high-quality, tight toleranced components machined from bar stock. Zetwerk also finishes as-cast and as-forged components. Zetwerk can provide a range of materials, from soft aluminum and brass to hard titanium and cobalt-chrome alloys."
            },
            {
              "products_and_services": "Forging",
              "description": "Zetwerk offers a variety of forging types, including drop forging, hot/cold forging, rolled ring forging, and press forging. We provide forged parts using lower-alloy and carbon steels, as well as alloys of aluminum, brass, and bronze. Once forged, components can be post-processed by precision CNC machining and surface finishing."
            },
            {
              "products_and_services": "Injection Molding",
              "description": "Injection molding can produce parts in a wide range of sizes and complexity. Small features, intricate geometries, and thin walls can be captured with ease using the injection molding process. Injection-molded components can be post-processed via various surface finishing options such as painting and texturing."
            },
            {
              "products_and_services": "Sheet Metal Stamping",
              "description": "Zetwerk produces custom sheet metal stampings in a wide range of custom materials. Some of the materials include cold-rolled steel (CRS), hot-rolled steel, stainless steel, galvanized, brass, aluminum, and beryllium copper among others. We offer complete secondary operations including powder coating, painting, anodizing, silk screening, and pad printing. Assembly services are also available."
            },
            {
              "products_and_services": "Prototyping and Pre-Production",
              "description": "Zetwerk helps companies and entrepreneurs around the world manufacture prototypes for their businesses. There is no minimum order quantity, and your business can benefit from Zetwerk's design and process expertise as well as our ability to quickly scale up production."
            },
            {
              "products_and_services": "Investment Casting",
              "description": "Zetwerk produces investment cast components using carbon steel, low-alloy steel, and stainless steel grades, in addition to a wide variety of non-ferrous metals such as aluminum, brass, and bronze. We also perform necessary secondary operations on the cast parts such as finish machining, heat treatment, and surface treatment."
            },
            {
              "products_and_services": "Assemblies",
              "description": "With large flexible layout factories, Zetwerk is able to set up dedicated assembly lines as per the volume requirements for a wide variety of electro-mechanical products and assemblies."
            }
          ]
        }
      }
    ]
  },
  {
    "key": "liabilities_and_pending_loans",
    "label": "Liabilities and Pending Loans",
    "summary": [
      {
        "label": "High Guarantees Issued",
        "riskSeverity": "Medium"
      },
      {
        "label": "Borrower and Pending Loans",
        "riskSeverity": "Medium"
      },
      {
        "label": "Liquidity Risk",
        "riskSeverity": "Medium"
      },
      {
        "label": "Deferred Tax Liabilities",
        "riskSeverity": "Medium"
      },
      {
        "label": "Statutory Dues",
        "riskSeverity": "Medium"
      }
    ],
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "title": "Claims Against the Group Not Acknowledged as Debts",
          "entries": [
            {
              "key": "high_value_guarantees",
              "label": "High Guarantees Issued",
              "value": "The significant increase in guarantees issued by the group from INR 73,063.00 Lakhs in 2022 to INR 1,05,520.00 Lakhs in 2023 could pose a financial risk if these guarantees are called upon.",
              "riskSeverity": "Medium"
            },
            {
              "key": "liquidity_risk",
              "label": "Liquidity Risk",
              "value": "The company relies heavily on short-term borrowings, which could lead to liquidity problems if they are unable to refinance these borrowings or if their business performance deteriorates.",
              "riskSeverity": "Medium"
            },
            {
              "key": "borrower_and_pending_loans",
              "label": "Borrower and Pending Loans",
              "value": "The company has granted loans to other entities, with no specified repayment period. This poses a risk of non-repayment and potential financial losses. Further investigation is needed into the nature of these loans and the creditworthiness of the borrowers. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "deferred_tax_liabilities",
              "label": "Deferred Tax Liabilities",
              "value": "The company has a significant amount of deferred tax liabilities, which need to be managed effectively. These liabilities have the potential to impact the company's financial performance. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "statutory_dues",
              "label": "Statutory Dues",
              "value": "The company has a significant amount of statutory dues payable, which need to be managed effectively. Failure to meet these obligations could result in penalties and legal issues. ",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "data": {
          "title": "Provisions",
          "entries": [
            {
              "key": "provision_for_employee_benefits",
              "label": "Provision for Employee Benefits",
              "value": "The company has a significant provision for employee benefits, primarily related to gratuity and compensated absences. This provision is based on actuarial valuations and is subject to uncertainty, which could potentially lead to adjustments in future periods.",
              "riskSeverity": "NA"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "financial_performance_and_risks",
    "label": "Financial Performance",
    "summary": [
      {
        "label": "Concentrated Revenue Source",
        "riskSeverity": "Medium"
      },
      {
        "label": "Credit Risk on Deposits",
        "riskSeverity": "Medium"
      },
      {
        "label": "Liquidity Risk",
        "riskSeverity": "Medium"
      },
      {
        "label": "Related Party Transactions",
        "riskSeverity": "Medium"
      },
      {
        "label": "Cashflow from Operating Activities",
        "riskSeverity": "Medium"
      }
    ],
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "entries": [
            {
              "key": "concentrated_revenue_source",
              "label": "Concentrated Revenue Source",
              "value": "The Group's revenue is heavily concentrated on two customers, representing 6.92% and 17.76% of total revenue in the year ending March 31, 2023. This high concentration could pose a risk if these customers experience financial difficulties or decide to switch suppliers. The Group should consider diversifying its customer base to mitigate this risk.",
              "riskSeverity": "Medium"
            },
            {
              "key": "credit_risk_on_deposits",
              "label": "Credit Risk on Deposits",
              "value": "The company holds significant amounts in fixed deposits, which represent a credit risk if the depository institution were to default.",
              "riskSeverity": "Medium"
            },
            {
              "key": "liquidity_risk_assessment",
              "label": "Liquidity Risk",
              "value": "The company relies heavily on short-term borrowings to finance its operations. This could pose a liquidity risk if the company faces difficulties in accessing credit markets. Additionally, the company does not maintain significant cash reserves, which could make it difficult to meet its short-term obligations if unexpected events occur. The company should consider strategies to improve its liquidity position, such as securing long-term financing or increasing its cash reserves. ",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "title": "Other Risks",
          "entries": [
            {
              "key": "related_party_transactions",
              "label": "Related Party Transactions",
              "value": "The company engages in significant related party transactions, which could pose a risk of conflicts of interest and potential financial losses. Further analysis is required to understand the nature and terms of these transactions, and to assess their impact on the company's financial performance. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "cashflow_from_operating_activities",
              "label": "Cashflow from Operating Activities",
              "value": "The company experienced a decrease in cash flow from operating activities, which may indicate a problem with revenue generation or cost control. ",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "data": {
          "title": "Consolidated P&L",
          "columns": [
            {
              "key": "year",
              "label": "Year"
            },
            {
              "key": "net_sales",
              "label": "Net Sales (INR Cr)"
            },
            {
              "key": "ebidta",
              "label": "EBITDA (INR Cr)"
            },
            {
              "key": "ebidta_margin",
              "label": "EBITDA Margin (%)"
            },
            {
              "key": "pat",
              "label": "PAT (INR Cr)"
            },
            {
              "key": "pat_margin",
              "label": "PAT Margin (%)"
            }
          ],
          "rows": [
            {
              "year": "FY21",
              "net_sales": "835.47",
              "ebidta": "-38.39",
              "ebidta_margin": "-4.6%",
              "pat": "-41.19",
              "pat_margin": "-4.9%"
            },
            {
              "year": "FY22",
              "net_sales": "4964.49",
              "ebidta": "-74.64",
              "ebidta_margin": "-1.5%",
              "pat": "-58.72",
              "pat_margin": "-1.2%"
            },
            {
              "year": "FY23",
              "net_sales": "11448.66",
              "ebidta": "66.93",
              "ebidta_margin": "0.6%",
              "pat": "-108.06",
              "pat_margin": "-0.9%"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "data": {
          "title": "Consolidated Balance Sheet",
          "columns": [
            {
              "key": "year",
              "label": "Year"
            },
            {
              "key": "total_debt",
              "label": "Total Debt (INR Cr)"
            },
            {
              "key": "tnw",
              "label": "TNW (INR Cr)"
            },
            {
              "key": "atnw",
              "label": "ATNW (INR Cr)"
            },
            {
              "key": "cash_and_equivalents",
              "label": "Cash & Equivalents (INR Cr)"
            },
            {
              "key": "net_fa",
              "label": "Net FA (INR Cr)"
            },
            {
              "key": "nwc",
              "label": "NWC (INR Cr)"
            },
            {
              "key": "contingent_liabilities",
              "label": "Contingent Liabilities (INR Cr)"
            }
          ],
          "rows": [
            {
              "year": "FY21",
              "total_debt": "83.73",
              "tnw": "1274.03",
              "atnw": "1274.03",
              "cash_and_equivalents": "1856",
              "net_fa": "40",
              "nwc": "3180",
              "contingent_liabilities": "731"
            },
            {
              "year": "FY22",
              "total_debt": "806.35",
              "tnw": "3717.77",
              "atnw": "3710.74",
              "cash_and_equivalents": "1877",
              "net_fa": "85",
              "nwc": "2842",
              "contingent_liabilities": "1067"
            },
            {
              "year": "FY23",
              "total_debt": "1049.82",
              "tnw": "3536.65",
              "atnw": "3487.53",
              "cash_and_equivalents": "2182",
              "net_fa": "109",
              "nwc": "3516",
              "contingent_liabilities": "1100"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "auditor_comment_highlights",
    "label": "Auditor Comment Highlights",
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "data": {
          "title": "Risk Areas and Considerations",
          "entries": [
            {
              "key": "stock_reporting_deviations",
              "label": "Deviations in Stock Reporting",
              "value": "The company's quarterly stock statements filed with banks differ from its books of account. This raises concerns about inventory management and potential misrepresentation to lenders. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "unspecified_repayment_loans",
              "label": "Loans with Unspecified Repayment",
              "value": "The company has granted loans to other entities without specifying a repayment period. This is a significant deviation from standard lending practices and poses a risk of non-repayment and potential financial losses. ",
              "riskSeverity": "High"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "p&l_and_cashflow",
    "label": "P&L and Cashflow",
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "data": {
          "title": "Highlights",
          "entries": [
            {
              "key": "revenue_growth",
              "label": "Revenue Growth",
              "value": "Revenue grew significantly, almost 38% year-on-year. This is a positive indicator for the company's performance. However, we need to investigate the reasons for this significant growth to assess sustainability and potential future performance. "
            },
            {
              "key": "net_income_decrease",
              "label": "Net Income Decrease",
              "value": "The company experienced a significant decrease in net income, which is a negative indicator for its performance and needs further investigation. "
            },
            {
              "key": "operating_income_decrease",
              "label": "Operating Income Decrease",
              "value": "The company experienced a decrease in operating income, which is a negative indicator for its performance and needs further investigation. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "cashflow_from_operating_activities_decrease",
              "label": "Cashflow from Operating Activities Decrease",
              "value": "The company experienced a decrease in cash flow from operating activities, which may indicate a problem with revenue generation or cost control. ",
              "riskSeverity": "Medium"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "related_party_transactions",
    "label": "Related Party Transactions",
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Highlights",
          "entries": [
            {
              "key": "related_party_transactions",
              "label": "Related Party Transactions",
              "value": "The report mentions that Zetwerk has acquired 6 companies, including joint ventures, and aims to grow inorganically with an aim to expand their existing product capabilities as well as add incremental customer base. The ability of the group to successfully shift from a project-based to a product-based business mix whilst ramping up the operations in its subsidiaries and acquired entities will be critical towards achieving this growth and remain monitorable. The report also mentions that the company has a large number of suppliers and contractors who are SMEs and MSMEs. The company provides large advances to these suppliers, which exposes the group to high credit risk from vendors.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "title": "Related Party Transactions",
          "entries": [
            {
              "key": "transactions_joint_ventures_subsidies",
              "label": "Transactions with Joint Ventures and Subsidiaries",
              "value": "Zetwerk Kinetix Technologies Private Limited**: Payments made on behalf of the joint venture, payments on behalf of the subsidiary, salary payments on behalf of the subsidiary, and investment in the joint venture. Significant increase in revenue and expense transactions from FY 2021-22 to FY 2022-23.",
              "riskSeverity": "Medium"
            },
            {
              "key": "transactions_joint_ventures_subsidies_2",
              "label": "Transactions with Joint Ventures and Subsidiaries",
              "value": "SIPS-LUMINO-ZETWERK JV: Sale of goods & services, payments made on behalf of the joint venture. Significant increase in revenue and expense transactions from FY 2021-22 to FY 2022-23. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "transactions_joint_ventures_subsidies_3",
              "label": "Transactions with Joint Ventures and Subsidiaries",
              "value": "Winsharp Electronics Pvt Ltd: Investment in Joint Venture.  Revenue and expense transactions have remained the same between FY 2021-22 and FY 2022-23.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "data": {
          "title": "Risk Areas and Considerations",
          "entries": [
            {
              "key": "high_value_transactions",
              "label": "High-value Transactions",
              "value": "The significant amounts involved in transactions with joint ventures and subsidiaries, particularly the sale of goods and services to SIPS-LUMINO-ZETWERK JV, indicate a high level of interdependence. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "related_party_balances",
              "label": "Related Party Balances",
              "value": "The substantial amounts outstanding to SIPS-LUMINO-ZETWERK JV, Zetwerk Kinetix Technologies Private Limited, and Winsharp Electronics Pvt Ltd. may indicate a dependence on these related parties for revenue generation. This could pose a credit risk if these parties are unable to meet their obligations.",
              "riskSeverity": "Medium"
            },
            {
              "key": "deferred_consideration",
              "label": "Deferred Consideration",
              "value": "The deferred consideration payable for the acquisition of Pinaka Aerospace Solutions Private Limited and Unimacts Global LLC represents a significant financial commitment. The terms of this deferred consideration and the associated risks should be carefully reviewed.",
              "riskSeverity": "Medium"
            },
            {
              "key": "joint_venture_control",
              "label": "Joint Venture Control",
              "value": "The Group's significant investment in various joint ventures (e.g., SIPL-ZMBPL JV, CVK-Zetwerk JV, JPPL ZMBPL JV) raises concerns about the level of control the Group exerts over these ventures and the potential for conflicts of interest.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "title": "Risk Areas & Considerations",
          "entries": [
            {
              "key": "high_value_transactions",
              "label": "High-value Transactions",
              "value": "The significant amounts involved in transactions with joint ventures and subsidiaries, particularly the sale of goods and services to SIPS-LUMINO-ZETWERK JV, indicate a high level of interdependence. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "related_party_loans",
              "label": "Related Party Loans",
              "value": "The Company has provided significant loans to related parties, which could potentially pose credit risk if these parties face financial difficulties.",
              "riskSeverity": "Medium"
            },
            {
              "key": "concentration_risk",
              "label": "Concentration Risk",
              "value": "The Group's revenue is heavily concentrated among a few major customers, which could make the company vulnerable to changes in these customers' business performance. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "debenture_repayment",
              "label": "Debenture Repayment",
              "value": "The Company has issued debentures, and its ability to repay these debentures on time depends on its financial performance, which could be affected by various factors including market conditions and the overall economic environment.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "data": {
          "title": "Other Highlights",
          "entries": [
            {
              "key": "loans_and_receivables",
              "label": "Loans and Receivables",
              "value": "The company has significant loans and receivables with related parties, including loans with unspecified repayment periods. This poses a risk of non-repayment and potential financial losses. Further investigation is needed into the terms of these transactions to understand the risk associated with these loans. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "share_capital",
              "label": "Share Capital",
              "value": "The company has a significant amount of share capital held by a related party, which could give that party significant influence over the company's operations and financial performance. ",
              "riskSeverity": "Medium"
            },
            {
              "key": "terms_and_conditions",
              "label": "Terms and Conditions",
              "value": "The company has not provided details about the specific terms and conditions for its related party transactions, which makes it difficult to assess the risks associated with these transactions. ",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://drive.google.com/file/d/1bwXUUsP2gAGrXKql_NKljTOJ9ItT-whK/view",
          "label": "Annual Report 2022-2023"
        },
        "data": {
          "title": "Related Party Transactions - Factsheet",
          "columns": [
            {
              "key": "related_party",
              "label": "Related Party"
            },
            {
              "key": "relationship",
              "label": "Relationship"
            },
            {
              "key": "transaction_type",
              "label": "Transaction Type"
            },
            {
              "key": "amount_lakhs",
              "label": "Amount (Lakhs)"
            },
            {
              "key": "analysis",
              "label": "Analysis"
            }
          ],
          "rows": [
            {
              "related_party": "Zetwerk Kinetix Technologies Private Limited",
              "relationship": "Joint venture",
              "transaction_type": "Sale of Goods & Services",
              "amount_lakhs": "11,908.42",
              "analysis": "Zetwerk Kinetix Technologies Private Limited is a joint venture partner, which implies shared ownership and control. This suggests a closer relationship and potentially lower credit risk. The transaction involves the sale of goods and services, indicating an ongoing commercial relationship. However, considering the  nature of the transaction and the joint venture structure, careful assessment of the counterparty's financial health and track record is crucial. Monitoring the repayment history and any potential financial distress signs in Zetwerk Kinetix Technologies Private Limited is crucial."
            },
            {
              "related_party": "Zetwerk Kinetix Technologies Private Limited",
              "relationship": "Joint venture",
              "transaction_type": "Payments made on behalf of joint venture",
              "amount_lakhs": "59.31",
              "analysis": "This transaction demonstrates financial support provided by Zetwerk to the joint venture. Such support could indicate a stronger commitment to the venture's success. However, the  amount is relatively small, indicating that the risk exposure may be limited.  Monitoring the repayment history and any potential financial distress signs in Zetwerk Kinetix Technologies Private Limited is crucial."
            },
            {
              "related_party": "Winsharp Electronics Private Limited",
              "relationship": "Jointly controlled entity",
              "transaction_type": "Investment in joint venture",
              "amount_lakhs": "4,000.00",
              "analysis": "Winsharp Electronics Private Limited is a jointly controlled entity, indicating shared control and potential exposure to the venture's financial performance. The significant investment amount  suggests a high level of commitment to the venture. However, assessing the financial health and track record of Winsharp Electronics Private Limited is crucial to evaluate the associated credit risk. The joint venture structure may present some level of risk mitigation, but thorough due diligence and ongoing monitoring are essential."
            },
            {
              "related_party": "Zetwerk Kinetix Technologies Private Limited",
              "relationship": "Joint venture",
              "transaction_type": "Payments made on behalf of joint venture",
              "amount_lakhs": "284.91",
              "analysis": "This transaction further highlights financial support provided by Zetwerk to the joint venture.  However, the amount is relatively small, indicating that the risk exposure may be limited.  Monitoring the repayment history and any potential financial distress signs in Zetwerk Kinetix Technologies Private Limited is crucial."
            },
            {
              "related_party": "Winsharp Electronics Private Limited",
              "relationship": "Jointly controlled entity",
              "transaction_type": "Loan receivable",
              "amount_lakhs": "912.26",
              "analysis": "This transaction reveals a loan extended by Zetwerk to the jointly controlled entity. It implies a direct financial relationship and a potential for credit risk. The loan amount is substantial, indicating a considerable financial exposure for Zetwerk. Careful due diligence on Winsharp Electronics Private Limited's financial health, repayment history, and overall business performance is crucial.  The jointly controlled entity structure might offer some risk mitigation, but ongoing monitoring and risk assessments are essential."
            },
            {
              "related_party": "Zetwerk Kinetix Technologies Private Limited",
              "relationship": "Joint venture",
              "transaction_type": "Interest Income",
              "amount_lakhs": "105.32",
              "analysis": "Zetwerk Kinetix Technologies Private Limited is a joint venture partner, which implies shared ownership and control. This suggests a closer relationship and potentially lower credit risk. The transaction involves interest income, indicating an ongoing commercial relationship. However, considering the nature of the transaction and the joint venture structure, careful assessment of the counterparty's financial health and track record is crucial. Monitoring the repayment history and any potential financial distress signs in Zetwerk Kinetix Technologies Private Limited is crucial."
            },
            {
              "related_party": "Zetwerk Kinetix Technologies Private Limited",
              "relationship": "Joint venture",
              "transaction_type": "Interest Income",
              "amount_lakhs": "60.48",
              "analysis": "Zetwerk Kinetix Technologies Private Limited is a joint venture partner, which implies shared ownership and control. This suggests a closer relationship and potentially lower credit risk. The transaction involves interest income, indicating an ongoing commercial relationship. However, considering the nature of the transaction and the joint venture structure, careful assessment of the counterparty's financial health and track record is crucial. Monitoring the repayment history and any potential financial distress signs in Zetwerk Kinetix Technologies Private Limited is crucial."
            }
          ]
        }
      }
    ]
  },
  {
    "key": "capital",
    "label": "Capital",
    "value": [
      {
        "objectType": "dictionary",
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Highlights",
          "entries": [
            {
              "key": "capital_raised",
              "label": "Capital Raised",
              "value": "During fiscal 2024, Zetwerk has raised Rs. 1,130 crore. Backed by continued equity infusion, the group has an estimated networth of about Rs. 4245 crore as on March 31, 2024 (post PAT losses, provisions related to exited businesses and notional expenses). The capital structure is expected to remain healthy driven by strong networth and moderate leverage. The management stance is to maintain gross gearing well below 0.5 times over the medium term."
            },
            {
              "key": "upcoming_requirements",
              "label": "Upcoming Requirements",
              "value": "The report does not mention any specific information about the company's upcoming capital requirements. However, the report mentions that the company's capital structure is expected to sustain going forward backed by Zetwerk management's committed stance of maintaining gross gearing well below 0.5 times at all points in time, whilst also maintaining substantial liquidity buffer."
            },
            {
              "key": "planned_capital",
              "label": "Planned Capital",
              "value": "The company has plans to grow inorganically by acquiring companies and expanding its product capabilities. This will require additional capital investment. The company's strong financial profile and commitment to maintaining substantial liquidity buffer suggest that it will be able to raise the necessary capital to fund its growth plans."
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "data": {
          "entries": [
            {
              "key": "equity_capital",
              "label": "Equity Capital",
              "value": "The company has a significant amount of equity capital, which suggests that it is well capitalized and has access to funds for future investments and growth. "
            },
            {
              "key": "future_requirements",
              "label": "Future Requirements",
              "value": "The company has not provided information about its future capital requirements, which makes it difficult to assess its ability to meet those requirements. "
            },
            {
              "key": "future_plans",
              "label": "Future Plans",
              "value": "The company has not provided information about its future plans, which makes it difficult to assess its strategic direction and potential growth opportunities. "
            }
          ]
        }
      }
    ]
  },
  {
    "key": "assets_and_collateral",
    "label": "Assets & Collateral",
    "value": [
      {
        "objectType": "dictionary",
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "entries": [
            {
              "key": "assets_and_collateral",
              "label": "Assets and Collateral",
              "value": "The report does not provide specific information about the company's assets and collateral. However, the report mentions that the company has a large customer base with a repeat customer base of 90% and that the company's cash flow is expected to improve with the shift to a product-based business model, which has a lower working capital cycle. This suggests that the company has a strong asset base and that its collateral is likely to be sufficient to support its debt obligations."
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "data": {
          "entries": [
            {
              "key": "non_current_assets",
              "label": "Non-Current Assets",
              "value": "The company has a significant amount of non-current assets, which could indicate that it is investing in its future growth and development. "
            },
            {
              "key": "intangible_assets",
              "label": "Intangible Assets",
              "value": "The company has a significant amount of intangible assets, which could indicate that it has a strong brand and intellectual property that provides a competitive advantage. "
            },
            {
              "key": "collateral",
              "label": "Collateral",
              "value": "The company has not provided information about the specific collateral it uses to secure loans, which makes it difficult to assess the value of its assets and its ability to meet its financial obligations. "
            }
          ]
        }
      }
    ]
  },
  {
    "key": "rating_agency_reports",
    "label": "Rating Agency Reports",
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Highlights",
          "entries": [
            {
              "key": "key_highlights",
              "label": "Key Highlights",
              "value": "Zetwerk has a strong financial risk profile with a robust networth, comfortable gearing, and ample liquidity. The company has a large customer base with a repeat customer base of 90%.  Revenue growth is expected to remain robust over the medium term, driven by both organic and inorganic growth. The company has a well-established vendor network and a strong track record of successfully completing projects. Working capital intensive operations remain a key monitorable for the company.",
              "riskSeverity": "NA"
            },
            {
              "key": "credit_risks",
              "label": "Credit Risks",
              "value": "Zetwerk's credit risks are mitigated by its strong financial profile, diverse customer base, and established vendor network. However, the company's limited track record of operations and working capital intensive operations expose it to potential credit risks. The company's low operating margins and subdued debt protection measures also raise concerns about its ability to manage its debt obligations.",
              "riskSeverity": "Medium"
            },
            {
              "key": "borrower_and_pending_loans",
              "label": "Borrower and Pending Loans",
              "value": "The company has granted loans to other entities, with no specified repayment period. This poses a risk of non-repayment and potential financial losses. Further investigation is needed into the nature of these loans and the creditworthiness of the borrowers.",
              "riskSeverity": "Medium"
            },
            {
              "key": "other_liabilities",
              "label": "Other Liabilities",
              "value": "The company's other liabilities are not specifically mentioned in the report. However, the report mentions that the company's gearing and total outside liabilities to adjusted networth are estimated to remain around 0.25 and 1.1-1.3 times as on March 31, 2024, suggesting that the company has a moderate level of other liabilities.",
              "riskSeverity": "Low"
            },
            {
              "key": "p&l_and_cashflow",
              "label": "P&L and Cashflow",
              "value": "Zetwerk's operating margins are low, at around 1.27% in fiscal 2024. This is due to unabsorbed fixed costs, losses from exited/rundown segments, and high corporate expenses. The company's cash flow is expected to improve with the shift to a product-based business model, which has a lower working capital cycle. The company's cash accruals are expected to be in the range of Rs. 60-90 crore over the medium term.",
              "riskSeverity": "Medium"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Other Observations",
          "columns": [
            {
              "key": "aspect",
              "label": "Aspect"
            },
            {
              "key": "details",
              "label": "Details"
            }
          ],
          "rows": [
            {
              "aspect": "Credit Risks",
              "details": "The group has a strong financial risk profile with robust networth, comfortable gearing and ample liquidity. The capital structure is expected to sustain going forward backed by Zetwerk management's committed stance of maintaining gross gearing well below 0.5 times at all points in time, whilst also maintaining substantial liquidity buffer."
            },
            {
              "aspect": "Borrower & Pending Loans",
              "details": "Zetwerk has raised Rs. 1,130 crore during fiscal 2024, resulting in gearing and total outside liabilities to adjusted networth estimated to remain around 0.25 and 1.1-1.3 times as on March 31, 2024. The group has an estimated networth of about Rs. 4245 crore as on March 31, 2024."
            },
            {
              "aspect": "P&L and Cashflow",
              "details": "The group has a diverse set of operations, diversified geographical presence and clientele, and a focus on strengthening its risk management policies. The group benefits from a large 1,000+ customer base which includes reputed players such as NTPC Renewables Energy Limited, Nextracker, Boat, Jio group etc. The group currently has a presence in the USA, South-east Asia and Middle East / Africa to cater to the local markets / customers."
            },
            {
              "aspect": "Capital: Raised, Upcoming Requirements & Planned",
              "details": "The group has raised Rs. 1,130 crore during fiscal 2024, resulting in gearing and total outside liabilities to adjusted networth estimated to remain around 0.25 and 1.1-1.3 times as on March 31, 2024. The group has an estimated networth of about Rs. 4245 crore as on March 31, 2024."
            },
            {
              "aspect": "Assets & Collateral",
              "details": "The group has well diversified operations with presence in various segments including steel fabrication and precision parts manufacturing, electronic products, aerospace and defense and trading of metals. The company is shifting its business mix from a project orientated mix to product orientated segments gradually. This has resulted in reduced project sales to 25% of total sales in fiscal 2024 as compared to approximately 30% last year."
            }
          ]
        }
      },
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Rating Summary",
          "entries": [
            {
              "key": "summary_of_rating",
              "label": "Summary of Rating",
              "value": "CRISIL Ratings has reaffirmed its ratings on the bank loan facilities, non-convertible debenture and commercial paper of Zetwerk Manufacturing Businesses Private Limited (Zetwerk, part of the Zetwerk group) at 'CRISIL A-/Stable/CRISIL A2+'."
            },
            {
              "key": "reasons_for_rating_migration",
              "label": "Reasons for Rating Migration",
              "value": "The ratings reflect the group's strong financial risk profile marked by robust networth, comfortable gearing and ample liquidity. The capital structure is expected to sustain going forward backed by Zetwerk management's committed stance of maintaining gross gearing well below 0.5 times at all points in time, whilst also maintaining substantial liquidity buffer."
            },
            {
              "key": "historical_ratings",
              "label": "Historical Ratings",
              "value": "The company's historical ratings are not available in this report. However, the report mentions that the company's credit profile has improved over the past year, with a reduction in working capital cycle and improvement in margins leading to improvement in the interest coverage."
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Instruments - Factsheet",
          "columns": [
            {
              "key": "instrument",
              "label": "Instrument"
            },
            {
              "key": "type",
              "label": "Type"
            },
            {
              "key": "amount",
              "label": "Amount (Rs. Crore)"
            }
          ],
          "rows": [
            {
              "instrument": "Total Bank Loan Facilities",
              "type": "Long Term",
              "amount": "2500"
            },
            {
              "instrument": "Non-convertible Debentures",
              "type": "Long Term",
              "amount": "200"
            },
            {
              "instrument": "Commercial Paper",
              "type": "Short Term",
              "amount": "75"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Rating - Details",
          "columns": [
            {
              "key": "rating_type",
              "label": "Rating Type"
            },
            {
              "key": "rating",
              "label": "Rating"
            },
            {
              "key": "rating_date",
              "label": "Rating Date"
            }
          ],
          "rows": [
            {
              "rating_type": "Long Term Instruments",
              "rating": "CRISIL A-/Stable",
              "rating_date": "July 04, 2024"
            },
            {
              "rating_type": "Short Term Instruments",
              "rating": "CRISIL A2+",
              "rating_date": "July 04, 2024"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Rating Migration",
          "columns": [
            {
              "key": "rating_date",
              "label": "Rating Date"
            },
            {
              "key": "rating",
              "label": "Rating"
            },
            {
              "key": "rating_change",
              "label": "Rating Change"
            }
          ],
          "rows": [
            {
              "rating_date": "December 28, 2022",
              "rating": "CRISIL A-/Stable",
              "rating_change": "Reaffirmed"
            },
            {
              "rating_date": "November 18, 2022",
              "rating": "CRISIL A2+",
              "rating_change": "Reaffirmed"
            },
            {
              "rating_date": "July 06, 2023",
              "rating": "CRISIL A-/Stable",
              "rating_change": "Reaffirmed"
            },
            {
              "rating_date": "July 06, 2023",
              "rating": "CRISIL A2+",
              "rating_change": "Reaffirmed"
            }
          ]
        }
      },
      {
        "objectType": "table",
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "title": "Historical Ratings",
          "columns": [
            {
              "key": "rating_type",
              "label": "Rating Type"
            },
            {
              "key": "rating_date",
              "label": "Rating Date"
            },
            {
              "key": "rating",
              "label": "Rating"
            }
          ],
          "rows": [
            {
              "rating_type": "Fund Based Facilities",
              "rating_date": "July 06, 2023",
              "rating": "CRISIL A-/Stable"
            },
            {
              "rating_type": "Non-Fund Based Facilities",
              "rating_date": "July 06, 2023",
              "rating": "CRISIL A2+"
            },
            {
              "rating_type": "Commercial Paper",
              "rating_date": "July 06, 2023",
              "rating": "CRISIL A2+"
            },
            {
              "rating_type": "Non Convertible Debentures",
              "rating_date": "July 06, 2023",
              "rating": "CRISIL A-/Stable"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "industry_analysis",
    "label": "Industry Analysis",
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "entries": [
            {
              "key": "industry_analysis_and_risks",
              "label": "Industry Analysis and Risks",
              "value": "The manufacturing industry is highly competitive and subject to various risks, including fluctuations in demand, commodity prices, and labor costs. The industry is also characterized by high capital intensity and a need for continuous innovation to remain competitive. Zetwerk operates in a diverse set of industries, which helps to mitigate some of the risks associated with any single sector. However, the company's dependence on a large number of suppliers and contractors exposes it to risks of supply chain disruptions and quality control issues.",
              "riskSeverity": "Medium"
            }
          ]
        }
      }
    ]
  },
  {
    "key": "market_analysis",
    "label": "Market Analysis",
    "value": [
      {
        "objectType": "dictionary",
        "aiGenerated": true,
        "source": {
          "link": "https://www.crisilratings.com/mnt/winshare/Ratings/RatingList/RatingDocs/ZetwerkManufacturingBusinessesPrivateLimited_July%2006,%202023_RR_320391.html",
          "label": "Ratings Report - CRISIL"
        },
        "data": {
          "entries": [
            {
              "key": "market_and_competitor_analysis_and_risks",
              "label": "Market and Competitor Analysis and Risks",
              "value": "Zetwerk's key competitors include traditional manufacturing companies, as well as online platforms that connect manufacturers with buyers. The company faces competition from both domestic and international players. The company's ability to compete effectively in this market will depend on its ability to differentiate its offerings, maintain a strong track record of performance, and build strong relationships with its customers. The company's ability to manage its growth and profitability in the long term will also be influenced by its ability to adapt to changes in the market and to respond to the challenges posed by its competitors.",
              "riskSeverity": "Medium"
            }
          ]
        }
      }
    ]
  }
 ]
  export default ZETWORK_Data;