import React, { useState } from "react";
import { Box, Grid, TextField, Checkbox, FormControlLabel, MenuItem, Chip, Select, OutlinedInput, Button, Card, CardContent, Typography, Divider, InputLabel, Tabs, Tab } from "@mui/material";

// Sample sectors and tags
const sectors = [
  "Aerospace & Defence",
  "Agro Chemicals",
  "Air Transport Service",
  "Alcoholic Beverages",
  "Auto Ancillaries",
  "Automobile",
  "Banks",
  "Bearings",
  "Cables",
  "Capital Goods - Electrical Equipment",
  "Capital Goods - Non Electrical Equipment",
  "Castings, Forgings & Fastners",
  "Cement",
  "Cement - Products",
  "Ceramic Products",
  "Chemicals",
  "Computer Education",
  "Construction",
  "Consumer Durables",
  "Credit Rating Agencies",
  "Crude Oil & Natural Gas",
  "Diamond, Gems and Jewellery",
  "Diversified",
  "Dry cells",
  "E-Commerce/App based Aggregator",
  "Edible Oil",
  "Education",
  "Electronics",
  "Engineering",
  "Entertainment",
  "ETF",
  "Ferro Alloys",
  "Fertilizers",
  "Finance",
  "Financial Services",
  "FMCG",
  "Gas Distribution",
  "Glass & Glass Products",
  "Healthcare",
  "Hotels & Restaurants",
  "Infrastructure Developers & Operators",
  "Infrastructure Investment Trusts",
  "Insurance",
  "IT - Hardware",
  "IT - Software",
  "Leather",
  "Logistics",
  "Marine Port & Services",
  "Media - Print/Television/Radio",
  "Mining & Mineral products",
  "Miscellaneous",
  "Non Ferrous Metals",
  "Oil Drill/Allied",
  "Online Media",
  "Packaging",
  "Paints/Varnish",
  "Paper",
  "Petrochemicals",
  "Pharmaceuticals",
  "Plantation & Plantation Products",
  "Plastic products",
  "Plywood Boards/Laminates",
  "Power Generation & Distribution",
  "Power Infrastructure",
  "Printing & Stationery",
  "Quick Service Restaurant",
  "Railways",
  "Readymade Garments/ Apparells",
  "Real Estate Investment Trusts",
  "Realty",
  "Refineries",
  "Refractories",
  "Retail",
  "Sanitaryware",
  "Ship Building",
  "Shipping",
  "Steel",
  "Stock/ Commodity Brokers",
  "Sugar",
  "Telecom-Handsets/Mobile",
  "Telecomm Equipment & Infra Services",
  "Telecomm-Service",
  "Textiles",
  "Tobacco Products",
  "Trading",
  "Tyres",
];
const tags = ["Large Cap", "Mid Cap", "Small Cap", "Growth", "Value", "Dividend"]; // Example tags
const circularOptions = ["NSE", "BSE", "SEBI", "ICCL"];
const newsOptions = ["NSE","BSE","Money Control", "SEBI", "MINT", "Zee Business", "The Economic Times"];


const Configuration = () => {
  const [selectedSector, setSelectedSector] = useState("");
  const [shareNames, setShareNames] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [otherTags, setOtherTags] = useState([]); // State for custom tags
  const [checkboxes, setCheckboxes] = useState({
    investmentHistory: false,
    watchList: false,
    interests: false,
    recentViews: false,
    circulars: false,
    news: false,
  });
const [circularSelections, setCircularSelections] = useState({
    NSE: true, // Pre-selected for prototype
    BSE: true,
    SEBI: true, // Pre-selected for prototype
    ICCL: false,
  });

  const [newsSelections, setNewsSelections] = useState({
    NSE: true, // Pre-selected for prototype
    BSE: true,
    "Money Control": true, // Pre-selected for prototype
    SEBI: false,
    MINT: true, // Pre-selected for prototype
    "Zee Business": false,
    "The Economic Times": false,
 
  });

  const [value, setValue] = useState(1); // State for tab selection

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle sector dropdown change
  const handleSectorChange = (event) => {
    setSelectedSector(event.target.value);
  };

  // Handle adding share name on Enter key
  const handleShareNameAdd = (event) => {
    if (event.key === "Enter" && event.target.value.trim()) {
      setShareNames((prev) => [...prev, event.target.value.trim()]);
      event.target.value = ""; // Clear input after adding
    }
  };

  // Handle deleting share name chip
  const handleShareNameDelete = (nameToDelete) => {
    setShareNames((prev) => prev.filter((name) => name !== nameToDelete));
  };

  // Handle tags selection
  const handleTagChange = (event) => {
    const value = event.target.value;
    setSelectedTags(typeof value === "string" ? value.split(",") : value);
  };

  // Handle adding other tags on Enter key
  const handleOtherTagAdd = (event) => {
    if (event.key === "Enter" && event.target.value.trim()) {
      setOtherTags((prev) => [...prev, event.target.value.trim()]);
      event.target.value = ""; // Clear input after adding
    }
  };

  // Handle deleting other tag chip
  const handleOtherTagDelete = (tagToDelete) => {
    setOtherTags((prev) => prev.filter((tag) => tag !== tagToDelete));
  };

  // Handle checkboxes change
  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={4}>
      {/* Left Side (Filters) */}
      <Grid item xs={12} md={3}>
        <Box padding={3} sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: "white" }}>
          <Typography variant="h5" gutterBottom>
            Configuration
          </Typography>

          {/* Tabs for Source Configuration and Custom Filters */}
          <Tabs value={value} onChange={handleTabChange}>
            <Tab label="Source" sx={{ flex: 1 }} />
            <Tab label="Custom Filters" sx={{ flex: 1 }} />
          </Tabs>

          {value === 0 && (
           <Grid container spacing={4}>
           {/* Left Side: Circulars */}
           <Grid item xs={12} md={6}>
             <Box padding={3} sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: "white" }}>
               <Typography variant="h5" gutterBottom>
                 Circulars
               </Typography>
     
               {circularOptions.map((option) => (
                 <FormControlLabel
                   key={option}
                   control={
                     <Checkbox
                       checked={circularSelections[option]}
                      //  onChange={handleCircularChange}
                       name={option}
                       size="small"
                     />
                   }
                   label={option}
                 />
               ))}
     
             
             </Box>
           </Grid>
     
           {/* Right Side: News */}
           <Grid item xs={12} md={6}>
             <Box padding={3} sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: "white" }}>
               <Typography variant="h5" gutterBottom>
                 News
               </Typography>
     
               {newsOptions.map((option) => (
                 <FormControlLabel
                   key={option}
                   control={
                     <Checkbox
                       checked={newsSelections[option]}
                      //  onChange={handleNewsChange}
                       name={option}
                       size="small"
                     />
                   }
                   label={option}
                 />
               ))}
     
            
             </Box>
           </Grid>
         </Grid>
          )}

          {value === 1 && (
            <Box marginTop={2}>
              <TextField label="Filter Title" fullWidth margin="normal" variant="outlined" size="small" />
              {/* Sector Dropdown */}
              <TextField label="Sector" select value={selectedSector} onChange={handleSectorChange} fullWidth margin="normal" variant="outlined" size="small">
                {sectors.map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
              </TextField>

              {/* Share Name Multi-Chip Input */}
              <Box display="flex" flexWrap="wrap" gap={1} marginTop={1}>
                {shareNames.map((name) => (
                  <Chip key={name} label={name} onDelete={() => handleShareNameDelete(name)} />
                ))}
                <TextField label="Add Share Name" onKeyDown={handleShareNameAdd} fullWidth margin="normal" placeholder="Press Enter to add share name" variant="outlined" size="small" />
              </Box>

              {/* Filter by Tags Multi-Select */}
              <InputLabel id="market-cap-label" sx={{ marginTop: "20px" }}>
                Market Cap
              </InputLabel>
              <Select
                multiple
                labelId="market-cap-label"
                value={selectedTags}
                onChange={handleTagChange}
                input={<OutlinedInput />}
                renderValue={(selected) => (
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {selected.map((tag) => (
                      <Chip key={tag} label={tag} />
                    ))}
                  </Box>
                )}
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
              >
                {tags.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>

              {/* Other Tags Multi-Chip Input */}
              <Box display="flex" flexWrap="wrap" gap={1} marginTop={2}>
                {otherTags.map((tag) => (
                  <Chip key={tag} label={tag} onDelete={() => handleOtherTagDelete(tag)} />
                ))}
                <TextField label="Add Other Tag" onKeyDown={handleOtherTagAdd} fullWidth margin="normal" placeholder="Press Enter to add other tag" variant="outlined" size="small" />
              </Box>

              {/* Checkbox Filters */}
              <Box display="flex" flexDirection="column" marginTop={2}>
                <FormControlLabel control={<Checkbox checked={checkboxes.investmentHistory} onChange={handleCheckboxChange} name="investmentHistory" size="small" />} label="Investment History" />
                <FormControlLabel control={<Checkbox checked={checkboxes.watchList} onChange={handleCheckboxChange} name="watchList" size="small" />} label="Watch List" />
                <FormControlLabel control={<Checkbox checked={checkboxes.interests} onChange={handleCheckboxChange} name="interests" size="small" />} label="Interests" />
                <FormControlLabel control={<Checkbox checked={checkboxes.recentViews} onChange={handleCheckboxChange} name="recentViews" size="small" />} label="Recent Views" />
              </Box>
              <Box marginTop={3}>
                <Button variant="contained" color="primary" fullWidth size="large">
                  Save Filters
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>

      {/* Right Side (Preview or Additional Content) */}
      <Grid item xs={12} md={9}>
        <Box padding={3} sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: "white" }}>
          <Typography variant="h5" gutterBottom>
            Preview/Content
          </Typography>
          <Divider />
          {/* Additional Content or Preview can be added here */}
          {/* Right Side (Static Saved Filters Cards) */}
          <Grid item xs={12} md={12}>
  <Box padding={3} sx={{ borderRadius: 2, backgroundColor: "white" }}>
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Example Cards */}
      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary">
            Tech and Energy Growth
          </Typography>
          <Divider />
          <Typography variant="body1">Stocks: Wipro, Reliance</Typography>
          <Typography variant="body1">Categories: Blue Chip, Growth</Typography>
          <Typography variant="body2">Preferences: High ROI, Portfolio Watch</Typography>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary">
            Midcap Innovators
          </Typography>
          <Divider />
          <Typography variant="body1">Stocks: Tech Mahindra, Sun Pharma</Typography>
          <Typography variant="body1">Categories: Mid Cap, Stability</Typography>
          <Typography variant="body2">Preferences: Growth Potential, Dividend Payouts</Typography>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary">
            Dynamic Financials and Health
          </Typography>
          <Divider />
          <Typography variant="body1">Stocks: Axis Bank, Biocon</Typography>
          <Typography variant="body1">Categories: Large Cap, Value</Typography>
          <Typography variant="body2">Preferences: Favorite List, Frequent Views</Typography>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary">
            High-Yield Energy and Utilities
          </Typography>
          <Divider />
          <Typography variant="body1">Stocks: ONGC, NTPC</Typography>
          <Typography variant="body1">Categories: Large Cap, Dividend</Typography>
          <Typography variant="body2">Preferences: High Dividends, Stable Returns</Typography>
        </CardContent>
      </Card>
      
      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary">
            Diverse Midcap Opportunities
          </Typography>
          <Divider />
          <Typography variant="body1">Stocks: Bajaj Finance, L&T Technology</Typography>
          <Typography variant="body1">Categories: Mid Cap, Growth</Typography>
          <Typography variant="body2">Preferences: Innovation, Market Trends</Typography>
        </CardContent>
      </Card>
      
    </Box>
  </Box>
</Grid>

        </Box>
      </Grid>
    </Grid>
  );
};

export default Configuration;
