/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  IconButton,
  TextField,
  Chip,
  Button,
  Paper,
  Card,
  Tooltip,
  Typography,
  alpha,
  CircularProgress,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  List,
  Divider,
  hexToRgb,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // Filled version
import ThumbDownIcon from "@mui/icons-material/ThumbDown"; // Filled version
import SendIcon from "@mui/icons-material/Send";
import SortIcon from "@mui/icons-material/Sort";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FeedIcon from "@mui/icons-material/Feed";
import ArticleIcon from "@mui/icons-material/Article";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinIcon from "@mui/icons-material/PushPin"; // Example pin icon

import TwitterIcon from "@mui/icons-material/X";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import TimelineIcon from "@mui/icons-material/Timeline";
import UserMessageCard from "./components/UserMessageCard";
import BotMessageCard from "./components/BotMessageCard";
import botIcon from "./Chatbot.svg";

const sectors = [
  "Aerospace & Defence",
  "Agro Chemicals",
  "Air Transport Service",
  "Alcoholic Beverages",
  "Auto Ancillaries",
  "Automobile",
  "Banks",
  "Bearings",
  "Cables",
  "Capital Goods - Electrical Equipment",
  "Capital Goods - Non Electrical Equipment",
  "Castings, Forgings & Fastners",
  "Cement",
  "Cement - Products",
  "Ceramic Products",
  "Chemicals",
  "Computer Education",
  "Construction",
  "Consumer Durables",
  "Credit Rating Agencies",
  "Crude Oil & Natural Gas",
  "Diamond, Gems and Jewellery",
  "Diversified",
  "Dry cells",
  "E-Commerce/App based Aggregator",
  "Edible Oil",
  "Education",
  "Electronics",
  "Engineering",
  "Entertainment",
  "ETF",
  "Ferro Alloys",
  "Fertilizers",
  "Finance",
  "Financial Services",
  "FMCG",
  "Gas Distribution",
  "Glass & Glass Products",
  "Healthcare",
  "Hotels & Restaurants",
  "Infrastructure Developers & Operators",
  "Infrastructure Investment Trusts",
  "Insurance",
  "IT - Hardware",
  "IT - Software",
  "Leather",
  "Logistics",
  "Marine Port & Services",
  "Media - Print/Television/Radio",
  "Mining & Mineral products",
  "Miscellaneous",
  "Non Ferrous Metals",
  "Oil Drill/Allied",
  "Online Media",
  "Packaging",
  "Paints/Varnish",
  "Paper",
  "Petrochemicals",
  "Pharmaceuticals",
  "Plantation & Plantation Products",
  "Plastic products",
  "Plywood Boards/Laminates",
  "Power Generation & Distribution",
  "Power Infrastructure",
  "Printing & Stationery",
  "Quick Service Restaurant",
  "Railways",
  "Readymade Garments/ Apparells",
  "Real Estate Investment Trusts",
  "Realty",
  "Refineries",
  "Refractories",
  "Retail",
  "Sanitaryware",
  "Ship Building",
  "Shipping",
  "Steel",
  "Stock/ Commodity Brokers",
  "Sugar",
  "Telecom-Handsets/Mobile",
  "Telecomm Equipment & Infra Services",
  "Telecomm-Service",
  "Textiles",
  "Tobacco Products",
  "Trading",
  "Tyres",
];

const FeedbackComponent = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [likeFilled, setLikeFilled] = useState(false);
  const [dislikeFilled, setDislikeFilled] = useState(false);

  const handleFeedbackOpen = (type) => {
    setFeedbackType(type);
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setLikeFilled(false);
    setDislikeFilled(false);
  };

  const handleFeedbackSubmit = (feedback) => {
    console.log(`Feedback (${feedbackType}): ${feedback}`);
    handleFeedbackClose();
  };

  return (
    <Box display="flex" justifyContent="flex-end" sx={{ position: "relative" }}>
      <Tooltip title="Like">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setLikeFilled(true);
            handleFeedbackOpen("like");
          }}
        >
          {likeFilled ? <ThumbUpIcon color="success" /> : <ThumbUpOffAltIcon color="success" />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Dislike">
        <IconButton
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation
            setDislikeFilled(true);
            handleFeedbackOpen("dislike");
          }}
        >
          {dislikeFilled ? <ThumbDownIcon color="error" /> : <ThumbDownOffAltIcon color="error" />}
        </IconButton>
      </Tooltip>
      <FeedbackBox open={feedbackOpen} onClose={handleFeedbackClose} onSubmit={handleFeedbackSubmit} />
    </Box>
  );
};

const FeedbackBox = ({ open, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = (e) => {
    e.stopPropagation();
    onSubmit(feedback);
    setFeedback("");
  };

  return (
    open && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "-70px", // Adjust this value as needed
          right: "70px",
          width: "300px",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          zIndex: 1200, // Ensures it appears above other content
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Enter your feedback..."
          value={feedback}
          onChange={(e) => {
            e.stopPropagation();
            setFeedback(e.target.value);
          }}
          sx={{ marginBottom: "8px" }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleSubmit}
            size="small"
            endIcon={<SendIcon />}
            sx={{
              marginRight: "8px",
              color: "#fff",
              background: "#6EC1E4",
              "&:hover": {
                backgroundColor: "#6EC1E4",
                color: "#fff",
              },
            }}
          >
            Send
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={(e) => {
              onClose();
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    )
  );
};

const DynamicCard = ({ source, timeAgo, title, description, imageUrl, sourceLink, chipsData, chipsData2 }) => {
  return (
    <Card
      sx={{
        minHeight: "100px", // Reduced minimum height
        borderRadius: "18px", // Slightly reduced border radius
        // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.7)", // Reduced box shadow for a lighter look
        // marginBottom: "6px", // Reduced margin to fit more cards

        padding: "32px", // Reduced padding
        paddingBottom: "0px",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "center",
        transition: "transform 0.3s, box-shadow 0.3s",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px" }}>
          <TimelineIcon sx={{ color: "green", width: "20px", height: "20px", fontWeight: "600", marginRight: "10px", fontSize: "30px" }} />
          <Typography variant="h6" sx={{ color: "#2E3B4E", flex: 1, marginRight: "8px", fontSize: "18px",fontWeight:'550' }}>
            {title}
          </Typography>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {chipsData.map((chip, index) => (
              <Tooltip key={index} title={chip.label} arrow>
                <Chip
                  label={chip.value}
                  sx={{
                    // backgroundColor: chip.color ? alpha(chip.color, 0.15) : "#e0e0e0",
                    // color: `${chip.color}` || "#808080",
                    background:`${chip.color}`,
                    color:'#FFF',
                    fontWeight: "550",

                    marginRight: "12px", // Reduced margin for chips
                    marginBottom: "2px",
                    // borderRadius: "4px",
                    // fontWeight: "bold",
                    padding: "0px !important", // Reduced padding
                    border: `1px solid ${chip.color || "#e0e0e0"}`,
                    cursor: "pointer",
                    fontSize: "12px", // Reduced font size for chips
                  }}
                />
              </Tooltip>
            ))}
          </Box>
          <br />
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ marginRight: "20px", color: "#808080", fontSize: "16px", fontWeight: "550" }}>Matches with:</Box>
          {chipsData2.map((chip, index) => (
            <Tooltip key={index} title={chip.label} arrow>
              <Chip
                label={chip.label}
                sx={{
                  backgroundColor: "#f3e5f5", // Soft lavender background
                  color: "#79155B", // Bright purple text
                  fontWeight: "550",
                  border: `1px solid #8e24aa`, // Matching purple border

                  marginRight: "12px", // Reduced margin for chips
                  marginBottom: "2px",
                  // borderRadius: "4px",
                  // fontWeight: "bold",
                  padding: "0px !important", // Reduced padding

                  cursor: "pointer",
                  fontSize: "12px", // Reduced font size for chips
                }}
              />
            </Tooltip>
          ))}
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <Box>
            <Box
              sx={{
                fontSize: "12px",
                color: "#999",
                fontWeight: "500",
                marginBottom: "8px",
                cursor: "pointer", // Add pointer cursor for better UX when clicking
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent any unwanted card selection when clicking
                window.open(sourceLink, "_blank", "noopener,noreferrer"); // Open link in a new tab safely
              }}
            >
              {source} &bull; {timeAgo}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <Box sx={{ color: "#5F6368", marginBottom: "8px", lineHeight: 1.2, width: "100%", fontSize: "14px" }}>{description}</Box>
              <FeedbackComponent />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};




const AINews = () => {
  const content = [
    {
      objectType: "cards",
      aiGenerated: true,
      data: {
        entries: [
          {
            "label": "HCL Technologies Limited Announces Buyback",
            "value": "HCL Technologies Limited has announced a buyback of shares up to Rs 10,000 crores.",
            "brief_description": ["A share buyback by HCL Technologies Limited means the company will repurchase its shares, potentially increasing the stock's value.","However, this can reduce liquidity and denote fewer growth opportunities if the buyback is funded by debt, possibly adversely affecting stock valuation or limiting investment gains."],
            "date": "29-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: HCLTECH",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bearish",
                "color": "#E72929"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Share Buyback",
                "color": "#00ACC6"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Jindal Saw Limited Announces Face Value Split",
            "value": "Jindal Saw Limited will split its face value from Rs 2 per share to Re 1 per share.",
            "brief_description": ["Jindal Saw Limited's stock split from Rs 2 to Re 1 increases the number of shares while maintaining total value.", "For current or potential investors, this typically makes shares more affordable and enhances liquidity, possibly attracting more investors.", "While the intrinsic value doesn't change, the stock may become more appealing, potentially affecting your portfolio positively."],
            "date": "09-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: JINDALSAW",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Stock Split",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "Hercules Hoists Limited Announces Demerger",
            "value": "Hercules Hoists Limited will demerge a part of its business.",
            "brief_description": ["Hercules Hoists Limited will separate part of its business into a new entity.", "For shareholders, this can unlock value as both entities may perform better independently.", "If holding or planning to invest, assess the potential growth and financial health of each entity to understand the impact on your portfolio."],
            "date": "11-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: HERCULES",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Neutral",
                "color": "#3C3D37"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Demerger",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "stock_holding",
                "label": "Stock Holding"
              },
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Tata Motors Limited Announces Dividend",
            "value": "Tata Motors Limited has declared a dividend of Rs 1.50 per share.",
            "brief_description": ["Tata Motors Limited has announced a dividend payment of Rs 1.50 per share, which can be a positive sign for shareholders as it signifies the company's profitability and commitment to rewarding investors.", "If you are holding or planning to invest in Tata Motors Limited, consider the dividend payout and its impact on your overall returns."],
            "date": "08-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: TATAMOTORS",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Dividend",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "stock_holding",
                "label": "Stock Holding"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Reliance Industries Limited Announces Bonus Issue",
            "value": "Reliance Industries Limited has announced a bonus issue in the ratio of 1:1.",
            "brief_description": ["Reliance Industries Limited is issuing bonus shares in a 1:1 ratio, meaning for every share held, shareholders will receive an additional share.", "This typically increases liquidity and can make the stock more attractive to investors. However, it does not change the company's intrinsic value.", "The bonus issue may impact your portfolio depending on your investment strategy."],
            "date": "07-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: RELIANCE",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Bonus Issue",
                "color": "#009688"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "Infosys Limited Announces Rights Issue",
            "value": "Infosys Limited is planning a rights issue to raise capital.",
            "brief_description": ["Infosys Limited is looking to raise capital through a rights issue. Existing shareholders will be offered the opportunity to buy new shares at a discounted price, potentially diluting the value of existing shares.", "Depending on your investment goals, this may affect your portfolio. Consider the potential impact of the rights issue on the company's future performance and your overall investment strategy."],
            "date": "06-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: INFY",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bearish",
                "color": "#E72929"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Rights Issue",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "ICICI Bank Limited Announces Merger",
            "value": "ICICI Bank Limited is merging with another financial institution.",
            "brief_description": ["ICICI Bank Limited's merger with another financial institution could signify expansion and consolidation within the banking sector.", "As a shareholder or potential investor, you should consider the potential benefits and risks of this merger, such as increased market share, improved efficiency, or potential integration challenges. This could impact your portfolio depending on the merger's long-term success."],
            "date": "05-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: ICICIBANK",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Neutral",
                "color": "#3C3D37"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Merger",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "HDFC Bank Limited Announces Acquisition",
            "value": "HDFC Bank Limited is acquiring a controlling stake in another company.",
            "brief_description": ["HDFC Bank Limited's acquisition of a controlling stake in another company could indicate a strategic move to expand its business and market reach.", "As a shareholder or potential investor, it's important to understand the impact of this acquisition on the company's future growth and profitability. This can influence your investment decisions and the overall performance of your portfolio."],
            "date": "04-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: HDFCBANK",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Neutral",
                "color": "#3C3D37"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Acquisition",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Bharti Airtel Limited Announces a New Product Launch",
            "value": "Bharti Airtel Limited has launched a new product or service.",
            "brief_description": ["Bharti Airtel Limited's new product launch can signify innovation and competitiveness in the telecommunications market. For investors, this may indicate potential growth opportunities for the company.", "However, it's important to assess the market reception and impact on the company's financial performance before making any investment decisions. This may affect your portfolio depending on the success of the new product."],
            "date": "03-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: BHARTIARTL",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "New Product Launch",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "Wipro Limited Announces a New Contract Award",
            "value": "Wipro Limited has secured a significant new contract.",
            "brief_description": ["A new contract award to Wipro Limited can indicate strong business performance and growth potential. For investors, this may signal positive future prospects for the company.", "However, it's essential to assess the contract's value and its impact on the company's overall financial health. Depending on the contract's size and scope, this may influence your portfolio's performance."],
            "date": "02-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: WIPRO",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Contract Award",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Tech Mahindra Limited Announces a New Partnership",
            "value": "Tech Mahindra Limited has entered into a new strategic partnership.",
            "brief_description": ["Tech Mahindra Limited's new partnership can signal collaboration and potential growth opportunities. For investors, this may indicate positive prospects for the company.", "However, it's crucial to assess the partnership's impact on the company's financial performance and its long-term strategy. This can influence your investment decisions and affect your portfolio."],
            "date": "01-Oct-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: TECHM",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Partnership",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Kotak Mahindra Bank Limited Announces a New Branch Opening",
            "value": "Kotak Mahindra Bank Limited is opening a new branch.",
            "brief_description": ["Kotak Mahindra Bank Limited's new branch opening indicates expansion and growth in its physical presence. For investors, this may signify a commitment to serving a wider customer base.", "However, it's important to assess the impact of this expansion on the company's financial performance and its overall business strategy. This may affect your portfolio depending on the success of the new branch."],
            "date": "30-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: KOTAKBANK",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Branch Opening",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Axis Bank Limited Announces a New Product Launch",
            "value": "Axis Bank Limited has launched a new product or service.",
            "brief_description": ["Axis Bank Limited's new product launch can signify innovation and competitiveness in the banking sector. For investors, this may indicate potential growth opportunities for the company.", "However, it's important to assess the market reception and impact on the company's financial performance before making any investment decisions. This may affect your portfolio depending on the success of the new product."],
            "date": "29-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: AXISBANK",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "New Product Launch",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "State Bank of India Announces a New Branch Opening",
            "value": "State Bank of India is opening a new branch.",
            "brief_description": ["State Bank of India's new branch opening indicates expansion and growth in its physical presence. For investors, this may signify a commitment to serving a wider customer base.", "However, it's important to assess the impact of this expansion on the company's financial performance and its overall business strategy. This may affect your portfolio depending on the success of the new branch."],
            "date": "28-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: SBIN",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Branch Opening",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Larsen & Toubro Limited Announces a New Contract Award",
            "value": "Larsen & Toubro Limited has secured a significant new contract.",
            "brief_description": ["A new contract award to Larsen & Toubro Limited can indicate strong business performance and growth potential. For investors, this may signal positive future prospects for the company.", "However, it's essential to assess the contract's value and its impact on the company's overall financial health. Depending on the contract's size and scope, this may influence your portfolio's performance."],
            "date": "27-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: L&T",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Contract Award",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Tata Consultancy Services Limited Announces a New Partnership",
            "value": "Tata Consultancy Services Limited has entered into a new strategic partnership.",
            "brief_description": ["Tata Consultancy Services Limited's new partnership can signal collaboration and potential growth opportunities. For investors, this may indicate positive prospects for the company.", "However, it's crucial to assess the partnership's impact on the company's financial performance and its long-term strategy. This can influence your investment decisions and affect your portfolio."],
            "date": "26-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: TCS",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Long-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "Partnership",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              }
            ]
          },
          {
            "label": "Hindustan Unilever Limited Announces a New Product Launch",
            "value": "Hindustan Unilever Limited has launched a new product or service.",
            "brief_description": ["Hindustan Unilever Limited's new product launch can signify innovation and competitiveness in the fast-moving consumer goods market. For investors, this may indicate potential growth opportunities for the company.", "However, it's important to assess the market reception and impact on the company's financial performance before making any investment decisions. This may affect your portfolio depending on the success of the new product."],
            "date": "25-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: HINDUNILVR",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "New Product Launch",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "Maruti Suzuki India Limited Announces a New Model Launch",
            "value": "Maruti Suzuki India Limited has launched a new car model.",
            "brief_description": ["Maruti Suzuki India Limited's new car model launch can signify innovation and competitiveness in the automotive market. For investors, this may indicate potential growth opportunities for the company.", "However, it's important to assess the market reception and impact on the company's financial performance before making any investment decisions. This may affect your portfolio depending on the success of the new model."],
            "date": "24-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: Maruti",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "New Model Launch",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "Sun Pharma Industries Limited Announces a New Drug Launch",
            "value": "Sun Pharma Industries Limited has launched a new drug or product.",
            "brief_description": ["Sun Pharma Industries Limited's new drug launch can signify innovation and competitiveness in the pharmaceutical market. For investors, this may indicate potential growth opportunities for the company.", "However, it's important to assess the market reception and impact on the company's financial performance before making any investment decisions. This may affect your portfolio depending on the success of the new drug."],
            "date": "23-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: SUNPHARMA",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "New Drug Launch",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          },
          {
            "label": "Dr Reddy's Laboratories Limited Announces a New Drug Launch",
            "value": "Dr Reddy's Laboratories Limited has launched a new drug or product.",
            "brief_description": ["Dr Reddy's Laboratories Limited's new drug launch can signify innovation and competitiveness in the pharmaceutical market. For investors, this may indicate potential growth opportunities for the company.", "However, it's important to assess the market reception and impact on the company's financial performance before making any investment decisions. This may affect your portfolio depending on the success of the new drug."],
            "date": "22-Sep-2024",
            "source": "NSE India",
            "sourceLink": "https://www.nseindia.com/corporates/corporate-filings-corporate-actions",
            "symbol": "NSE: DRREDDY",
            "classification": [
              {
                "key": "stock_price_effect",
                "label": "Stock Price Effect",
                "value": "Bullish",
                "color": "#347928"
              },
              {
                "key": "impact_horizon",
                "label": "Impact Horizon",
                "value": "Short-term",
                "color": "#543310"
              },
              {
                "key": "category",
                "label": "Event Category",
                "value": "New Drug Launch",
                "color": "#007680"
              }
            ],
            "personalization_matches": [
              {
                "key": "watchlist",
                "label": "Watchlist"
              },
              {
                "key": "interests",
                "label": "Interests"
              },
              {
                "key": "user_activity",
                "label": "Activity History"
              }
            ]
          }
        ],
      },
    },
  ];

  // State to store the selected card details
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [displayedText, setDisplayedText] = useState("");
  const editableRef = useRef(null);

  // Sync the displayedText with the content of the contentEditable div
  useEffect(() => {
    if (editableRef.current) {
      editableRef.current.textContent = displayedText;
    }
  }, [displayedText]);

  const handleInput = () => {
    if (editableRef.current) {
      setDisplayedText(editableRef.current.textContent);
    }
  };
  const handleIconClick = async (postType) => {
    // Start loading
    setLoading(true);
    const userMessage = { sender: "user", text: postType };
    setMessages([...messages, userMessage]);
    setDisplayedText("");

    // Define word limits based on post type
    const wordLimits = {
      notification: "10-15 words",
      message: "15-20 words",
      whatsapp: "20-30 words",
      mail: "25-35 words",
      default: "35-45 words",
    };

    // Generate prompt with specific word limits
    const prompt = `Create a ${postType} post for: ${selectedCard.value}. The response should be in ${wordLimits[postType]}.and just content nothing else don't make grammatical or spelling mistakes. also mention the weather this data's imapact is bulish or bearish or neutral for the stock..and weather this stock news may have long term impact or short term `;

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, // Replace with your actual API key
          },
        }
      );

      // Access the generated text from the API response
      // const text = response.data.choices[0].message.content; // Use response.data directly

      // // Simulate the streaming effect
      // setDisplayedText(text);
      const botMessage = { sender: "bot", text: response.data.choices[0].message.content.trim() };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error:", error);
      setDisplayedText("Error generating post."); // Optional: Set error message
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };
  const sendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    setMessages([...messages, userMessage]);
    setInput("");
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: `${input} use the following data to ans ${JSON.stringify(selectedCard)} ` },
          ],
          // max_tokens: maxResponseTokens,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, // Replace with your actual API key
          },
        }
      );

      const botMessage = { sender: "bot", text: response.data.choices[0].message.content.trim() };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error:", error);
      // Provide more detailed error messages based on status
      const errorMessage = { sender: "bot", text: "Something went wrong. Please try again later." };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };

  // const streamText = (text) => {
  //   let index = -1;

  //   // Clear the displayed text before starting
  //   setDisplayedText("");

  //   // Use a closure to hold the current text value
  //   const interval = setInterval(() => {
  //     if (index < text.length) {
  //       // Update displayed text with a new character
  //       setDisplayedText((prevText) => prevText + text.charAt(index));
  //       index++;
  //     } else {
  //       clearInterval(interval); // Stop when all characters are displayed
  //     }
  //   }, 5); // Speed of character display (50 ms per character)
  // };

  // Function to close the detailed view
  const handleClose = () => {
    setSelectedCard(null);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSector, setSelectedSector] = useState("All Sectors");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedUser, setSelectedUser] = useState("All Users");
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [data, setData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = React.useState("Select");

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };

  // Divide news among the 3 users
  const getUserFilteredData = (entries) => {
    if (!selectedUser || selectedUser === "All Users") {
      return entries; // If no user is selected, return all entries
    }

    const userIndex = selectedUser === "User 1" ? 0 : selectedUser === "User 2" ? 1 : 2;
    const totalEntries = entries.length;
    const perUser = Math.ceil(totalEntries / 3);

    return entries.slice(userIndex * perUser, (userIndex + 1) * perUser);
  };

  // Filter, search, and sort data
  const filteredData = useMemo(() => {
    const filteredEntries = content[0].data.entries
      .filter(
        (entry) =>
          (selectedSector === "All Sectors" || entry.sector === selectedSector) && // Filter by sector
          (searchTerm === "" || entry.label.toLowerCase().includes(searchTerm.toLowerCase()) || entry.value.toLowerCase().includes(searchTerm.toLowerCase())) // Search by stock name or card data
      )
      .sort((a, b) => {
        return sortOrder === "asc"
          ? new Date(a.date) - new Date(b.date) // Sort ascending
          : new Date(b.date) - new Date(a.date); // Sort descending
      });

    return getUserFilteredData(filteredEntries); // Filter by user
  }, [content, searchTerm, selectedSector, sortOrder, selectedUser]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* Left side: List of dynamic cards */}
      <Box sx={{ width: selectedCard ? "60%" : "100%", padding: "20px", height: "80vh", overflow: "auto", background: "#f6f6f6 " }}>
        {/* Search and Filters in One Line */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            marginBottom: "20px",
            flexDirection: "row",
            width: "40%",
            marginLeft: "auto", // This pushes the box to the right
            justifyContent: "flex-end",
            alignItems: "center", // Ensures vertical alignment for elements
          }}
        >
          {/* <Typography variant="h5" sx={{ fontWeight: "bold", color: "#2E3B4E", flex: 3, marginLeft: "16px" }}>
    
          </Typography> */}

          {/* Search Input */}
          <TextField
            label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flex: 1, minWidth: 300 }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Filter by Sector */}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Sector</InputLabel>
            <Select
              value={selectedSector}
              onChange={(e) => setSelectedSector(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <FilterListIcon />
                </InputAdornment>
              }
              size="small"
              label="Sector"
            >
              <MenuItem value="All Sectors">All Sectors</MenuItem>
              {sectors.map((sector, index) => (
                <MenuItem key={index} value={sector}>
                  {sector}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" sx={{ minWidth: 250 }}>
            <InputLabel id="industry-select-label">Saved Searches</InputLabel>
            <Select
              labelId="industry-select-label"
              id="industry-select"
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <FilterListIcon />
                </InputAdornment>
              }
              value={selectedIndustry}
              onChange={handleIndustryChange}
              label="Select Industry"
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              <MenuItem value="Tech and Energy Growth">Tech and Energy Growth</MenuItem>
              <MenuItem value="Midcap Innovators">Midcap Innovators</MenuItem>
              <MenuItem value="Dynamic Financials and Health">Dynamic Financials and Health</MenuItem>
              <MenuItem value="High-Yield Energy and Utilities">High-Yield Energy and Utilities</MenuItem>
              <MenuItem value="Diverse Midcap Opportunities">Diverse Midcap Opportunities</MenuItem>
              <MenuItem value="Financial and IT Leaders">Financial and IT Leaders</MenuItem>
              <MenuItem value="Healthcare and Consumer Staples">Healthcare and Consumer Staples</MenuItem>
              <MenuItem value="Growth and Stability Mix">Growth and Stability Mix</MenuItem>
            </Select>
          </FormControl>
          {/* Filter by User */}
          <FormControl
            sx={{
              position: "absolute",
              minWidth: 120, // Reduce width
              top: "18px",
              right: "160px",
              fontSize: "12px", // Smaller font
              height: "35px", // Adjust height
            }}
            size="small"
          >
            <InputLabel sx={{ fontSize: "14px" }}>User</InputLabel> {/* Smaller font for label */}
            <Select
              value={selectedUser}
              size="small"
              onChange={(e) => setSelectedUser(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <FilterListIcon sx={{ height: "25px", width: "25px" }} /> {/* Smaller icon */}
                </InputAdornment>
              }
              label="User"
              sx={{
                fontSize: "12px", // Smaller font for select input
                height: "30px", // Adjust height for the input field
              }}
            >
              <MenuItem value="All Users" sx={{ fontSize: "12px" }}>
                All Users
              </MenuItem>
              <MenuItem value="User 1" sx={{ fontSize: "12px" }}>
                Ravi
              </MenuItem>
              <MenuItem value="User 2" sx={{ fontSize: "12px" }}>
                Aditi
              </MenuItem>
              <MenuItem value="User 3" sx={{ fontSize: "12px" }}>
                Kiran
              </MenuItem>
            </Select>
          </FormControl>

          {/* Sort by Date */}
          {/* <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Sort by Date</InputLabel>
            <Select
              value={sortOrder}
              size="small"
              onChange={(e) => setSortOrder(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SortIcon />
                </InputAdornment>
              }
              label="Sort by Date"
            >
              <MenuItem value="asc">Oldest First</MenuItem>
              <MenuItem value="desc">Newest First</MenuItem>
            </Select>
          </FormControl> */}
        </Box>

        {/* Render Cards */}
        {filteredData.length > 0 ? (
          filteredData.map((entry, index) => (
            <Box
              key={index}
              onClick={() => {
                setSelectedCard(entry);
              }}
              sx={{
                cursor: "pointer",
                marginBottom: "20px",
                borderLeft: selectedCard?.label === entry.label ? "5px solid #007680" : "none", // Add border if selected
                borderRadius: "18px", // Optional: Add some border radius for styling
                // padding: "10px", // Optional: Padding for visual spacing
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "-45px" }}>
                {/* Pin Icon on the left */}
                <Tooltip title="Pin to Top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering card selection
                      // Your logic to pin the card
                      // pinCardToTop(entry); // Implement this function
                    }}
                  >
                    <PinIcon />
                  </IconButton>
                </Tooltip>

                {/* Close Icon on the right */}
                <Tooltip title="Not Interested">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering card selection
                      // Your logic to remove the card
                      // dismissCard(entry); // Implement this function
                    }}
                  >
                    <CloseIcon sx={{ color: "#808080" }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <DynamicCard
                title={entry.label}
                description={entry.value}
                source={entry.source}
                timeAgo={entry.date}
                sourceLink={entry.sourceLink}
                chipsData={entry.classification}
                chipsData2={entry.personalization_matches}
              />
            </Box>
          ))
        ) : (
          <Box>No data found for the selected Sector.</Box>
        )}
      </Box>

      {/* Right side: Detailed news view */}
      <>
        {selectedCard && (
          <Box
            sx={{
              width: "40%",
              padding: "20px",
              borderLeft: "1px solid #ccc",
              position: "relative",
              marginLeft: "30px",
              background: "#f6f6f6 ",
              textAlign: "center", // Center content
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box
                sx={{
                  // marginBottom: "10px",
                  // padding: "8px 12px", // Add padding for spacing
                  fontSize: "15px",
                  width: "100%", // Full width for better layout
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center", // Align text with any icons you may add
                  color: "#555", // Softer grey for better readability
                  backgroundColor: "#f5f5f5", // Light background for contrast
                  borderRadius: "8px", // Rounded corners for a smooth look
                  // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                  flexWrap: "wrap",
                  whiteSpace: "normal",
                  wordBreak: "break-word", // Break only at necessary points for a cleaner wrap
                }}
              >
                <span style={{ fontWeight: "600", color: "#007680", marginRight: "5px" }}>AI Summary :</span>
                <ul style={{ color: "#555", flexWrap: "wrap", whiteSpace: "normal", wordBreak: "break-word", paddingLeft: "20px" }}>
  {selectedCard.brief_description.map((item, index) => (
    <li key={index}>{item}</li>
  ))}
</ul>
              </Box>

              <CloseIcon onClick={handleClose} sx={{ cursor: "pointer", fontSize: "30px" }} />
            </Box>

            {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              {selectedCard.classification.map((chip, index) => (
                <Tooltip key={index} title={chip.label} arrow>
                  <Chip
                    label={chip.value}
                    sx={{
                      backgroundColor: chip.color ? alpha(chip.color, 0.15) : "#e0e0e0",
                      color: chip.color || "#fff",
                      marginRight: "12px", // Reduced margin for chips
                      marginBottom: "2px",
                      borderRadius: "4px",
                      // fontWeight: "bold",
                      padding: "0px !important", // Reduced padding
                      border: `1px solid ${chip.color || "#e0e0e0"}`,
                      cursor: "pointer",
                      fontSize: "12px", // Reduced font size for chips
                    }}
                  />
                </Tooltip>
              ))}
            </Box> */}
            {/* <Box sx={{ marginTop: "26px", textAlign: "center", textWrap: "balance", fontSize: "14px",color:'#808080',fontWeight:'500' }}>{selectedCard.value}</Box>
            <Typography sx={{ color: "#999", marginTop: "16px" }}>
              {selectedCard.source} • {selectedCard.date}
            </Typography> */}

            {/* Editable Text Box */}
            {/* <Box
              sx={{
                margin: "auto",
                marginTop: "30px",
                whiteSpace: "pre-wrap",
                width: "65%",
                display: "flex",
                justifyContent: "center",
                fontSize: "1.2rem",
                border: "1px solid #ccc", // Optional: add a border for better visibility
                padding: "10px", // Optional: add some padding
                borderRadius: "5px", // Optional: rounded corners
                height: "200px",
                overflowY: "auto",
              }}
              contentEditable
              ref={editableRef} // Ref to directly access the div
              onInput={handleInput} // Update displayedText state on input
              suppressContentEditableWarning // Suppress React's warning for contentEditable
            /> */}
            <Paper
              elevation={3}
              sx={{
                borderRadius: "12px",
                width: "95%",
                height: "55vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
                margin: "auto",
                marginTop: "10px",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "12px",
                  backgroundColor: "#6EC1E4",
                  // padding: "10px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  position: "relative",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={botIcon}
                    alt="ChatBot"
                    style={{
                      height: "40px",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#FFF",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Krita-AI
                  </Typography>
                </Box>
            
              </Box> */}

              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  padding: "16px",
                  backgroundColor: "#F4F6F8",
                  borderRadius: "12px",
                  boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.5)",
                  // marginBottom: "16px",
                }}
              >
                {messages?.length === 0 && (
                  <Box
                    sx={{
                      fontFamily: "Arial, sans-serif", // Change the font family
                      fontSize: "14px", // Adjust the font size
                      color: "#333", // Change the text color
                      textAlign: "center", // Center the text
                      marginTop: "10vh", // Move it down a bit
                      transform: "translateY(-50%)", // Center it vertically
                      letterSpacing: "1px", // Add some letter spacing
                      // textTransform: "uppercase", // Uppercase the text
                      // fontWeight: "bold", // Make it bold
                      opacity: 0.8, // Adjust the opacity
                      transition: "opacity 0.3s ease", // Add a fade-in transition
                      "&:hover": {
                        opacity: 1, // Fade in on hover
                      },
                    }}
                  >
                    Select an icon below to generate
                     customized content   <br />for your
                   customers/investors.
                  </Box>
                )}
                <List sx={{ padding: 0 }}>
                  {messages.map((msg, index) => (
                    <React.Fragment key={index}>
                      {msg.sender === "user" ? <UserMessageCard text={msg.text} /> : <BotMessageCard text={msg.text} data={data} />}
                      <Divider sx={{ marginY: 1 }} />
                    </React.Fragment>
                  ))}
                </List>
                {loading && (
                  <CircularProgress
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#438BF5",
                      background: "conic-gradient(from 90deg at 50% 50%, rgba(21, 157, 255, 0) 0deg, #159DFF 359.96deg, rgba(21, 157, 255, 0) 360deg)",
                    }}
                    thickness={5}
                  />
                )}
              </Box>
              <Box
                sx={{
                  // marginBottom: "10px",
                  // padding: "8px 12px", // Add padding for spacing
                  fontSize: "16px",
                  width: "100%", // Full width for better layout
                  textAlign: "left",
                  display: "flex",
                  marginLeft: "10px",
                  alignItems: "center", // Align text with any icons you may add
                  color: "#555", // Softer grey for better readability
                  backgroundColor: "#f5f5f5", // Light background for contrast
                  borderRadius: "8px", // Rounded corners for a smooth look
                  // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                  flexWrap: "wrap",
                  whiteSpace: "normal",
                  wordBreak: "break-word", // Break only at necessary points for a cleaner wrap
                }}
              >
                <span style={{ fontWeight: "600", color: "#007680", marginRight: "5px" }}>Create Assets :</span>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around", marginBottom: "5px" }}>
                {/* Icon Buttons for Post Types */}
                <Tooltip title="Create Notification Post" arrow>
                  <IconButton onClick={() => handleIconClick("Notification")} sx={{ color: "#FFC107", padding: "0px" }}>
                    <NotificationsIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create News Feed Post" arrow>
                  <IconButton onClick={() => handleIconClick("News Feed")} sx={{ color: "#1976D2", padding: "0px" }}>
                    <FeedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Blog Post" arrow>
                  <IconButton onClick={() => handleIconClick("Blog")} sx={{ color: "#673AB7", padding: "0px" }}>
                    <ArticleIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Gmail Post" arrow>
                  <IconButton onClick={() => handleIconClick("Gmail")} sx={{ color: "#D93025", padding: "0px" }}>
                    <EmailIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Message" arrow>
                  <IconButton onClick={() => handleIconClick("Message")} sx={{ color: "#4CAF50", padding: "0px" }}>
                    <MessageIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create WhatsApp Post" arrow>
                  <IconButton onClick={() => handleIconClick("WhatsApp")} sx={{ color: "#25D366", padding: "0px" }}>
                    <WhatsAppIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create LinkedIn Post" arrow>
                  <IconButton onClick={() => handleIconClick("LinkedIn")} sx={{ color: "#0077B5", padding: "0px" }}>
                    <LinkedInIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Twitter Post" arrow>
                  <IconButton onClick={() => handleIconClick("Twitter")} sx={{ color: "#000", padding: "0px" }}>
                    <TwitterIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ borderRadius: "12px", display: "flex", alignItems: "center", paddingRight: "5px", mb: "5px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Ask a question..."
                  value={input}
                  size="small"
                  sx={{ border: "2px solid #6EC1E4", borderRadius: "12px", marginRight: "10px", marginLeft: "10px" }}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={sendMessage}
                  sx={{
                    marginRight: "8px",
                    color: "#fff",
                    backgroundColor: "#6EC1E4",

                    padding: "7px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    "&:hover": {
                      backgroundColor: "#6EC1E4", // Maintain the same background color on hover
                      color: "#fff", // Maintain the same text color on hover
                    },
                  }}
                  endIcon={<SendIcon sx={{ color: "#FFF" }} />}
                >
                  Send
                </Button>
              </Box>
            </Paper>

            {/* Copy and Share Buttons */}
            {/* <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "10px" }}>
              <Button variant="outlined" color="primary" onClick={handleCopyClick}>
                Copy Text
              </Button>
              <Button variant="contained" color="primary" onClick={handleShareClick} endIcon={<ShareIcon />}>
                Share
              </Button>
            </Box>

 
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={() => handleShareOption("everyone")}>Share with Everyone</MenuItem>
              <MenuItem onClick={() => handleShareOption("clients_with_holding")}>Share with Clients Holding</MenuItem>
              <MenuItem onClick={() => handleShareOption("clients_wishlist")}>Share with Clients in Wishlist</MenuItem>
            </Menu> */}
          </Box>
        )}
      </>
    </Box>
  );
};

export default AINews;
