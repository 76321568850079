/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Button, Card, CardContent, Menu, MenuItem, Typography, IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const BotMessageCard = ({ text }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Optional: alert("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShareOption = (option) => {
    // Implement your share logic here based on the option
    console.log(`Share with: ${option}`);
    handleMenuClose();
  };

  return (
    <Card sx={{ backgroundColor: "#ffffff", marginBottom: 2, borderRadius: "20px", marginRight: "50px", position: 'relative' }}>
      <CardContent>
        <Typography variant="body1" color="text.primary" sx={{textAlign:'left'}}>
          {text}
          {/* Add your chart or data rendering components here */}
        </Typography>
        {/* <Typography variant="caption" color="text.secondary">
          CAM-GPT
        </Typography> */}
      </CardContent>

      {/* Copy and Share Icons at Bottom Right */}
      <Box sx={{ position: "absolute", bottom: 10, right: 10, display: "flex",gap:'10'}}>
        <IconButton color="primary" sx={{fontSize:'10px',height:'15px',width:'15px',color:'#808080',marginRight:'10px'}} onClick={handleCopyClick}>
          <ContentCopyIcon />
        </IconButton>
        <IconButton color="primary" sx={{fontSize:'10px',height:'15px',width:'15px',color:'#808080'}} onClick={handleShareClick}>
          <ShareIcon />
        </IconButton>
      </Box>

      {/* Share Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
      <MenuItem onClick={() => handleShareOption("everyone")}>Everyone</MenuItem>
<MenuItem onClick={() => handleShareOption("clients_with_holding")}>Holders</MenuItem>
<MenuItem onClick={() => handleShareOption("clients_wishlist")}>Interested Clients</MenuItem>

      </Menu>
    </Card>
  );
};

export default BotMessageCard;
