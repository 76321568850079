import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, TextField, Button, List, Paper, Typography, Divider, IconButton } from "@mui/material";
import UserMessageCard from "./components/UserMessageCard"; // Import UserMessageCard
import BotMessageCard from "./components/BotMessageCard";  // Import BotMessageCard
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import SNCL_Data from "./SNCL";
import ZETWORK_Data from "./ZETWORK";
import botIcon from './Chatbot.svg';

const Chatbot = ({ selectedCompany, open, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedCompany === 'SADHANA NITRO CHEM LIMITED') {
      setData(SNCL_Data);
    } else if (selectedCompany === 'ZETWERK MANUFACTURING BUSINESSES PRIVATE LIMITED') {
      setData(ZETWORK_Data);
    }
  }, [selectedCompany]);

  const maxTokenLimit = 12000; // Reduced token limit for safety
  const reservedTokenLength = 1000; // Reserved tokens for system prompt and response
  const maxResponseTokens = 250; // Maximum tokens for the response
  
  // Function to estimate the number of tokens in a string
  const estimateTokenLength = (text) => {
    // Note: This is a rough estimate; use a more accurate method if needed
    return Math.ceil(text.length / 4); 
  };
  
  // Function to truncate text to a maximum length, adding ellipsis if needed
  const truncateText = (text, maxLength) => text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  
  // Function to ensure text fits within token limits and specified word count
  const adjustTextForLimits = (text, availableTokenLength) => {
    let adjustedText = text;
    const textTokenLength = estimateTokenLength(adjustedText);
    
    if (textTokenLength > availableTokenLength) {
      const maxLength = availableTokenLength * 4; // Rough estimate to fit within token limit
      adjustedText = truncateText(adjustedText, maxLength);
    }
    
    return adjustedText;
  };
  
  const sendMessage = async () => {
    if (input.trim() === "") return;
  
    const userMessage = { sender: "user", text: input };
    setMessages([...messages, userMessage]);
    setInput("");
  
    let dataString = JSON.stringify(data);
    const availableTokenLength = maxTokenLimit - reservedTokenLength - maxResponseTokens; // Space for API response
  
    // Adjust dataString length to fit within token limits
    dataString = adjustTextForLimits(dataString, availableTokenLength);
  
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: `${input}. Please use only the data provided below to respond to any query related to data. Do not say "I don't have enough information" if the data is not available. The data is: ${dataString}` }
          ],
          max_tokens: maxResponseTokens,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`, // Replace with your actual API key
          },
        }
      );
  
      const botMessage = { sender: "bot", text: response.data.choices[0].message.content.trim() };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error:", error);
      // Provide more detailed error messages based on status
      const errorMessage = { sender: "bot", text: "Something went wrong. Please try again later." };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };
  

  if (!open) return null; // Render nothing if the chatbot is not open

  return (
    <Paper elevation={3} sx={{ borderRadius: '12px', width: '100%', height: '85vh', display: 'flex', flexDirection: 'column', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)', position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '12px',
          backgroundColor: '#6EC1E4',
          padding: '10px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          position: 'relative'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={botIcon}
            alt="ChatBot"
            style={{
              height: '40px',
              marginRight: '12px',
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: '#FFF',
              fontWeight: '600',
              fontSize: '18px',
            }}
          >
            Krita-AI
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#FFCD00',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '16px',
          backgroundColor: '#F4F6F8',
          borderRadius: '12px',
          boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)',
          marginBottom: '16px',
        }}
      >
        <List sx={{ padding: 0 }}>
          {messages.map((msg, index) => (
            <React.Fragment key={index}>
              {msg.sender === "user" ? (
                <UserMessageCard text={msg.text} />
              ) : (
                <BotMessageCard text={msg.text} data={data} />
              )}
              <Divider sx={{ marginY: 1 }} />
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box sx={{ borderRadius: '12px', display: 'flex', alignItems: 'center', paddingRight: '5px', mb: '5px' }}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Ask a question..."
          value={input}
          size="small"
          sx={{ border:'2px solid #6EC1E4',borderRadius:"12px",marginRight:'10px' ,marginLeft:'10px'}}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
        />
        <Button
          variant="outlined"
          size="small"
          onClick={sendMessage}
          sx={{  marginRight: '8px',
            color: '#fff',
            backgroundColor: '#6EC1E4',

            padding:'7px',
            paddingLeft:'10px',
            paddingRight:'10px',
            '&:hover': {
              backgroundColor: '#6EC1E4', // Maintain the same background color on hover
              color: '#fff', // Maintain the same text color on hover
            }, }}
          endIcon={<SendIcon sx={{ color: '#FFF' }} />}
        >
          Send
        </Button>
      </Box>
    </Paper>
  );
};

export default Chatbot;
