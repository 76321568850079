import { Box } from "@mui/material";
import HomePage from "./HomePage";
import StockNotifications from "./StockNotifications";

function App() {
  return (
    <Box>
     
      {/* <HomePage /> */}
      <StockNotifications/>
    </Box>
  );
}

export default App;
