/* eslint-disable no-undef */
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
// import IconButton from '@mui/material/IconButton';
import { styled } from "@mui/material/styles";
import Header from "./Header";
import TabsComponent from "./Tabpanel"; // Adjusted import to match component name
import Chatbot from "./Chatbot";
import BotIcon from "./Chatbot.svg"; // Use any icon you prefer
import { Box } from "@mui/material";

const BotIconButton = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: "#6EC1E4",
  borderRadius: "50%",
  cursor:'pointer',
  color: "#fff",
  "&:hover": {
    backgroundColor: "#FFCD00",
  },
}));

const HomePage = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);


  const toggleChatbot = () => {
    setIsChatbotOpen((prevState) => !prevState);
  };
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
  };

  return (
    <div>
      <Header onCompanySelect={handleCompanySelect} /> {/* Pass handleCompanySelect as prop */}
      <Grid container spacing={2} style={{ padding: "10px" }}>
        <Grid item xs={isChatbotOpen ? 9 : 12}>
          <TabsComponent selectedCompany={selectedCompany} /> {/* Pass selectedCompany as prop */}
        </Grid>

        {isChatbotOpen && (
          <Grid item xs={3}>
            <Chatbot selectedCompany={selectedCompany} open={isChatbotOpen} onClose={() => setIsChatbotOpen(false)} />
          </Grid>
        )}
      </Grid>
      {!isChatbotOpen && selectedCompany && (
        <BotIconButton onClick={toggleChatbot}>
          <img
            src={BotIcon}
            alt="ChatBot"
            style={{
              height: "70px", // Adjust size of the icon
              // marginRight: '12px', // Space between icon and text
            }}
          />
        </BotIconButton>
      )}
    </div>
  );
};

export default HomePage;
